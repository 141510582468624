import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";

import ContentsPanel from "./ContentsPanel";

const ReportNav = ({ data, location }) => {
  return (
    <React.Fragment>
      <ContentsPanel
        chapters={data.chapters}
        allArticleIds={data.allArticleIds}
        location={location}
      />
    </React.Fragment>
  );
};

ReportNav.propTypes = {
  data: PropTypes.shape({
    chapters: PropTypes.array
  }),
  location: PropTypes.shape({})
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        allSoetChapter(sort: { fields: [sortOrder], order: ASC }) {
          edges {
            node {
              id
              entityId
              path
              subTitle
              title
              titleNumber
              soetArticle {
                id
                entityId
                path
                title
                titleNumber
              }
            }
          }
        }
        allSoetArticle {
          edges {
            node {
              entityId
            }
          }
        }
      }
    `}
    render={data => (
      <ReportNav
        data={{
          chapters: data.allSoetChapter.edges.map(edge => edge.node),
          allArticleIds: data.allSoetArticle.edges.map(
            edge => edge.node.entityId
          )
        }}
        {...props}
      />
    )}
  />
);
