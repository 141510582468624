module.exports = [{
      plugin: require('/home/ubuntu/frontend_build/plugins/wrap-with-context/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/ubuntu/frontend_build/plugins/tracking-soet/gatsby-browser.js'),
      options: {"plugins":[],"google":{"trackingId":"UA-36046017-2","anonymize":false}},
    },{
      plugin: require('/home/ubuntu/frontend_build/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The State of European Tech 2018","short_name":"State of European Tech 2018","start_url":"/","background_color":"#002b38","theme_color":"#99dbcf","display":"standalone","icon":"src/assets/favicon.png"},
    },{
      plugin: require('/home/ubuntu/frontend_build/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/ubuntu/frontend_build/src/components/Root/index.js"},
    },{
      plugin: require('/home/ubuntu/frontend_build/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
