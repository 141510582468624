import React from "react";
import PropTypes from "prop-types";
import ArticleHeader from "../ArticleHeader";
import ArticleFooterShare from "../ArticleFooterShare";
import Block from "../Block";
import BlockFreeText from "../BlockFreeText";
import BlockImageText from "../BlockImageText";
import BlockChartContainer from "../BlockChart/container";
import BlockChartImage from "../BlockChartImage";
import BlockImage from "../BlockImage";
import BlockImageGrid from "../BlockImageGrid";
import BlockInsight from "../BlockInsight";
import BlockSpacer from "../BlockSpacer";
import BlockQuote from "../BlockQuote";
import BlockVideo from "../BlockVideo";

import styles from "./index.module.css";

const blockInfo = {
  ChartBlock: {
    component: BlockChartContainer,
    backgroundColor: `white`,
    type: `Chart`,
    fullBleed: false,
    inverted: false,
    tightPadding: false
  },
  ChartImageBlock: {
    component: BlockChartImage,
    backgroundColor: `white`,
    type: `ChartImage`,
    fullBleed: false,
    inverted: false,
    tightPadding: false
  },
  FreeTextBlock: {
    backgroundColor: `white`,
    component: BlockFreeText,
    type: `Free Text`,
    fullBleed: false,
    inverted: false,
    tightPadding: false
  },
  ImageBlock: {
    component: BlockImage,
    backgroundColor: `white`,
    type: `Image`,
    fullBleed: true,
    inverted: false,
    tightPadding: false
  },
  ImageTextBlock: {
    backgroundColor: `white`,
    component: BlockImageText,
    type: `Image Text`,
    fullBleed: false,
    inverted: false,
    tightPadding: false
  },
  ImageGridBlock: {
    component: BlockImageGrid,
    backgroundColor: `white`,
    type: `Image Grid`,
    fullBleed: false,
    inverted: false,
    tightPadding: false
  },
  InsightBlock: {
    component: BlockInsight,
    backgroundColor: `#003C4F`,
    type: `Insight`,
    fullBleed: false,
    inverted: true
  },
  SpacerBlock: {
    component: BlockSpacer,
    backgroundColor: ``,
    type: `Spacer`,
    fullBleed: false,
    inverted: false,
    tightPadding: true
  },
  VideoBlock: {
    component: BlockVideo,
    backgroundColor: `#202528`,
    type: `Video`,
    fullBleed: true,
    inverted: false,
    tightPadding: false
  },
  QuoteBlock: {
    component: BlockQuote,
    backgroundColor: `#feefdb`,
    secondBackgroundColor: `#FDE3C0`,
    type: `Quote`,
    fullBleed: false,
    inverted: true,
    tightPadding: false
  }
};

/**
 * Render a block from a description
 *
 * Look up a property from blockInfo using block.type as the key.
 * Apply block specific props to the wrapping <Block> component, along with
 * block.props to <Block>'s child component.
 *
 *
 * @param {object} block See blockPropTypes definition
 * @param {int}    index so we can modify the first block
 */
const blockRenderer = function(block, index) {
  if (!block.entityType) return;
  const info = Object.assign({}, blockInfo[block.entityType]);
  if (!info) return null;
  const skipTitle = false;
  const Component = info.component;

  return (
    <Block
      backgroundColor={info.backgroundColor}
      fullBleed={info.fullBleed}
      tightPadding={info.tightPadding}
      inverted={info.inverted}
      key={index}
      skipTitle={skipTitle}
      type={info.type}
      id={`block-${this.entityId}-${block.priority}`} // eslint-disable-line babel/no-invalid-this
    >
      <Component
        {...block}
        skipTitle={skipTitle}
        titleNumber={this.titleNumber} // eslint-disable-line babel/no-invalid-this
      />
    </Block>
  );
};

class Article extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { blocks, title, path, titleNumber, entityId } = this.props;
    return (
      <div
        className={styles.root}
        id={`article-${entityId}`}
        data-observe-position
        data-pathname={path}
      >
        <ArticleHeader
          titleNumber={titleNumber}
          title={title}
          entityId={entityId}
          path={path}
        />
        {/* TODO: passing entityId in via _this_ is a bit gross */}
        {blocks &&
          blocks.map(blockRenderer, {
            entityId,
            titleNumber: titleNumber
          })}
        <ArticleFooterShare
          titleNumber={titleNumber}
          title={title}
          entityId={entityId}
          path={path}
        />
      </div>
    );
  }
}

const blockPropTypes = {
  entityType: PropTypes.oneOf(Object.keys(blockInfo)),
  id: PropTypes.string,
  props: PropTypes.shape({})
};

Article.propTypes = {
  entityId: PropTypes.number,
  articleType: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.shape(blockPropTypes)),
  excerpt: PropTypes.string,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleNumber: PropTypes.string.isRequired
  // location: PropTypes.shape({
  //   search: PropTypes.string,
  //   hash: PropTypes.string,
  //   pathname: PropTypes.string
  // }),
  // onSetChart: PropTypes.func,
  // onClearChart: PropTypes.func
};

export default Article;
