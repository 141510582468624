import { connect } from "react-redux";
import TopBar from "../TopBar";

import { requestReportDownload } from "../../state/download/report";

const withRedux = connect(
  () => ({}),
  dispatch => ({
    onDownloadClick() {
      dispatch(requestReportDownload());
    }
  })
);

export default withRedux(TopBar);
