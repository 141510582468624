import React from "react";
// import PropTypes from "prop-types";
import AllArticlesPanel from "../AllArticlesPanel";

// const AllArticlesTab = () => <React.Fragment>All</React.Fragment>;

const ContentsPanel = ({ chapters, allArticleIds, location }) => {
  return (
    <AllArticlesPanel chapters={chapters} windowPathname={location.pathname} />
  );
};

ContentsPanel.propTypes = AllArticlesPanel.propTypes;

export default ContentsPanel;
