import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

/**
 * These colours should be the same as in themeAtomico.css to ensure the
 * legend colours match up to the colours assigned by Highcharts. Highcharts
 * will loop through the colours in order from .highcharts-color-0 to
 * .highcharts-color-x, repeating from 0 if it needs to use more colours.
 */
// const highchartColorCount = 13;
const initialColors = [
  `#99dbcf`,
  `#e57945`,
  `#002b38`,
  `#F5CF53`,
  `#80969b`,
  `#ccede7`,
  `#f8af4a`,
  `#ccd5d7`,
  `#335560`,
  `#fae7a9`,
  `#ea946a`,
  `#668088`,
  `#ade2d9`,
  `#24838d`,
  `#eabead`,
  `#e57945`,
  `#e57945`
];

const colors = [...initialColors];
const axisColors = [
  `#01171c`,
  `#002b38`,
  `#0f4649`,
  `#216666`,
  `#2f7f79`,
  `#60a09e`,
  `#7fbab0`,
  `#9bd1c9`,
  `#c3e2dc`,
  `#e6f3f1`,
  `#f2f4f5`
];

// Group series make every 2 consecutive series colours the same
const groupSeriesColors = [
  `#99dbcf`,
  `#99dbcf`,
  `#e57945`,
  `#e57945`,
  `#002b38`,
  `#002b38`,
  `#e57945`,
  `#e57945`,
  `#80969b`,
  `#80969b`,
  `#ccede7`,
  `#ccede7`,
  `#f8af4a`,
  `#f8af4a`,
  `#ccd5d7`,
  `#ccd5d7`,
  `#335560`,
  `#335560`,
  `#fae7a9`,
  `#fae7a9`,
  `#ea946a`,
  `#ea946a`,
  `#668088`,
  `#668088`,
  `#ade2d9`,
  `#668088`,
  `#99aaaf`,
  `#99aaaf`,
  `#fbd7a4`,
  `#fbd7a4`
];

// // make sure we have 13 colours to match colour classes in themeAtomico.css
// if (colors.length < highchartColorCount) {
//   while (colors.length < highchartColorCount) {
//     colors.push(initialColors);
//   }
// }

// colors.length = highchartColorCount;

class ChartLegend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: []
    };
  }

  onSeriesClick(index, e) {
    e.preventDefault();

    // Don't do anything for axis based legends
    if (this.props.axis) {
      return false;
    }

    // Callback to hide series on chart and update state to change opacity of
    // item that was clicked. Don't toggle if there's only one series.
    if (this.props.onSeriesToggle && this.props.series.length > 1) {
      this.props.onSeriesToggle(index);

      const hidden = this.state.hidden;
      if (hidden.indexOf(index) > -1) {
        hidden.splice(hidden.indexOf(index), 1);
      } else {
        hidden.push(index);
      }

      this.setState({
        hidden: hidden
      });
    }
  }

  render() {
    const { series, axis, grouped } = this.props;
    let colorRange = colors;

    if (axis) {
      colorRange = axisColors;
    } else if (grouped) {
      colorRange = groupSeriesColors;
    }

    let colourCounter = 0;

    return (
      <div className={styles.root}>
        <h4 className={styles.legendTitle}>Legend</h4>
        {series && (
          <ul className={styles.legendList}>
            {series.map((seri, i) => {
              // Line series coloured manually by highcharts at index 16
              // seri.type 'line' only used for column and line [mc]
              const colorIndex = seri.type == `line` ? 16 : colourCounter;
              if (seri.type !== `line`) {
                colourCounter++;
              }
              const discStyle = {
                backgroundColor: colorRange[colorIndex]
              };
              const boundClick = this.onSeriesClick.bind(this, i);
              const itemClass =
                this.state.hidden.indexOf(i) > -1
                  ? styles.legendItemHidden
                  : styles.legendItem;
              return (
                <li className={itemClass} key={i} onClick={boundClick}>
                  <div className={styles.legendItemDiscWrapper}>
                    <span className={styles.legendItemDisc} style={discStyle} />
                  </div>
                  <span className={styles.legendItemName}>{seri.name}</span>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

ChartLegend.propTypes = {
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.array // numbers or objects
    })
  ),
  axis: PropTypes.bool,
  grouped: PropTypes.bool,
  onSeriesToggle: PropTypes.func
};

export default ChartLegend;
