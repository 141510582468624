import React from "react";
import PropTypes from "prop-types";
import PrimaryButton from "../PrimaryButton";
import styles from "./index.module.css";
import { graphql, StaticQuery } from "gatsby";
import PartnerLogo from "../PartnerLogo";

const data = {
  sponsors: [`Slush`, `Orrick`],
  dataPartners: [
    `aon`,
    `cbre`,
    `cern`,
    `craft`,
    `dealroom`,
    `eban`,
    `eif`,
    `esi`,
    `flourish`,
    `global-university-venturing`,
    `indeed`,
    `invest-europe`,
    `lse`,
    `meetup`,
    `politico-pro`,
    `quid`,
    `shareworks`,
    `sifted`,
    `stack-overflow`,
    `graphext`
  ]
};

const inlineSnippet = {
  alignSelf: `center`
};

// const getFilename = partnerName =>
//   `${partnerName.toLowerCase().replace(/[.,\s]/g, ``)}.png`;

const DataPartnersWithoutStaticQuery = ({
  onDownloadClick,
  reportPath,
  images
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.contentCols}>
          <div className={styles.contentColSingle}>
            <h2 className={styles.title}>
              We are proud to present the single, most comprehensive data-driven
              analysis on European technology today.
            </h2>
          </div>
        </div>
        <div className={styles.contentCols}>
          <div className={styles.contentCol}>
            <p className={styles.smallerText}>
              We could not have created this report without our generous
              sponsors, an incredible set of data partners, and thousands of
              people in the region who have shared their insights. Thank you.
            </p>
          </div>
          <div className={styles.contentCol}>
            <p className={styles.subTitle}>Our data partners</p>
            <div className={styles.logos}>
              {data.dataPartners.map(dataPartner => (
                <div className={styles.logo} key={dataPartner}>
                  <PartnerLogo partner={dataPartner} />
                </div>
              ))}
            </div>
            <nav className={styles.nav}>
              <div className={styles.navItem}>
                <PrimaryButton
                  to={`/chapter/appendix/article/more-about-our-data-partners/`}
                  type="link"
                  arrowThin={true}
                >
                  More on our partners
                </PrimaryButton>
              </div>
              <div className={styles.navItem}>
                <a href="mailto:info@atomico.com?subject=Becoming a State Of European Tech partner">
                  <PrimaryButton type="button" email={true}>
                    Become a partner
                  </PrimaryButton>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className={styles.bottomBanner}>
        <div className={styles.inner}>
          <div className={styles.contentCols}>
            <div style={inlineSnippet} className={styles.contentCol}>
              <h3 className={styles.titleBanner}>Explore the data now</h3>
            </div>
            <div className={styles.contentCol}>
              <nav className={styles.nav}>
                <div className={styles.navItem}>
                  <PrimaryButton
                    to={reportPath}
                    inverted={true}
                    type="link"
                    arrow={true}
                  >
                    Read report
                  </PrimaryButton>
                </div>
                <div className={styles.navItem}>
                  <PrimaryButton
                    inverted={true}
                    download={true}
                    onClick={onDownloadClick}
                  >
                    Download
                  </PrimaryButton>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DataPartnersWithoutStaticQuery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})),
  reportPath: PropTypes.string,
  onDownloadClick: PropTypes.func
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: { sourceInstanceName: { eq: "data-partner-logos" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 80, maxWidth: 120) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
        }
        soetChapter {
          path
        }
      }
    `}
    render={data => (
      <DataPartnersWithoutStaticQuery
        reportPath={data.soetChapter.path}
        images={data.allFile.edges.map(
          ({ node }) => node.childImageSharp.fluid
        )}
        {...props}
      />
    )}
  />
);
