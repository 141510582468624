import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import styles from "./index.module.css";

const ArticleFooterWithoutStaticQuery = ({ title, siteUrl, path, next }) => {
  if (!next) return null;
  return (
    <div className={styles.root}>
      <nav className={styles.nav}>
        <Link to={next.path} className={styles.navItem}>
          <div className={styles.footerWrapper}>
            <div
              data-title-number={next.titleNumber}
              className={styles.titleNumber}
            ></div>
            <div className={styles.block}>
              <span className={styles.nextText}>
                Next {next.type === `chapter` ? `Chapter` : `Article`}
              </span>
              <hr className={styles.hr} />
              <span className={styles.nextTitle}>{next.title}</span>
            </div>
          </div>
        </Link>
      </nav>
    </div>
  );
};

// TODO: run this query directly from the Share component instead of here?
export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl: url
          }
        }
      }
    `}
    render={data => (
      <ArticleFooterWithoutStaticQuery
        siteUrl={data.site.siteMetadata.siteUrl}
        {...props}
      />
    )}
  />
);

ArticleFooterWithoutStaticQuery.propTypes = {
  siteUrl: PropTypes.string,
  path: PropTypes.string,
  next: PropTypes.shape({
    path: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    titleNumber: PropTypes.titleNumber
  })
};
