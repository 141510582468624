import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

import PartnerLogo from "../PartnerLogo";

const Insight = ({ title, stat, source, description, inverted }) => {
  const rootStyle = inverted ? styles.rootInverted : styles.root;
  return (
    <div className={rootStyle}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <hr className={styles.line} />
      </div>
      <dl className={styles.insight}>
        <dt className={styles.stat}>{stat}</dt>
        <dd className={styles.description}>{description}</dd>
      </dl>

      {source && (
        <h4 className={styles.source}>
          <span className={styles.sourceText}>Source:</span>
          <PartnerLogo partner={source} white={true} />
        </h4>
      )}
    </div>
  );
};

Insight.defaultProps = {
  inverted: false
};

Insight.propTypes = {
  title: PropTypes.string,
  stat: PropTypes.string,
  source: PropTypes.string,
  description: PropTypes.string,
  inverted: PropTypes.bool
};

export default Insight;
