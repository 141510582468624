import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { graphql, StaticQuery } from "gatsby";
import Svg from "../Svg";
import NavContext from "../NavContext";
import SearchInput from "../SearchInput";
import SearchPanel from "../../components/SearchInput/SearchPanel";

// import { MobileView } from "react-device-detect";

import menuIcon from "./menuIcon.svg";
import menuIconClose from "./menuIconClose.svg";
import searchIcon from "./searchIcon.svg";

const isReportRoute = path => /^(\/chapter\/|\/article\/)/.test(path);
const isSearchRoute = path =>
  path.startsWith(`/search-data`) || path.startsWith(`/search-articles`);

const MenuBarWithoutQuery = ({
  reportNavOpen,
  searchBarOpen,
  mobileDevice,
  toggleReportNav,
  showIsMobile,
  toggleSearchBar,
  closeReportNav,
  data,
  activePathname,
  pathname
}) => {
  // const closeIconClass = reportNavOpen ? styles.closeVisible : styles.close;
  // const menuButtonItem = reportNavOpen ? menuIcon : menuIconClose;
  const titles = data.allSoetArticle.edges.concat(data.allSoetChapter.edges);
  const titleMap = titles.reduce((map, item) => {
    map[item.node.path] = {
      title: item.node.title,
      titleNumber: item.node.titleNumber,
      nextPath: item.node.next ? item.node.next.path : false
    };
    return map;
  }, {});

  // let menuName = `Contents`;
  let titleNumber = ``;
  let title = ``;
  if (isReportRoute(activePathname) && titleMap[activePathname]) {
    titleNumber = titleMap[activePathname].titleNumber;
    title = titleMap[activePathname].title;
  }
  if (isSearchRoute(pathname)) {
    titleNumber = `Search`;
    title = ``;
  }

  return (
    <div className={styles.root}>
      <a className={styles.menu} onClick={toggleReportNav} title="Menu">
        {reportNavOpen ? (
          <Svg
            svg={menuIconClose}
            alt="Menu button"
            className={styles.menuIcon}
          />
        ) : (
          <Svg
            svg={menuIcon}
            alt="Menu button"
            onClick={closeReportNav}
            className={styles.menuIcon}
          />
        )}
      </a>
      {reportNavOpen ? (
        <h3 className={styles.titleOpenNav}>Menu</h3>
      ) : (
        <h1 className={styles.title}>
          <span className={styles.titleText}>{titleNumber} </span>
          <span className={styles.titleTextWithMargin}> {title}</span>
        </h1>
      )}

      {reportNavOpen ? (
        ``
      ) : (
        <div onClick={toggleSearchBar} className={styles.search}>
          {searchBarOpen ? (
            <Svg
              svg={menuIconClose}
              alt="Search button"
              className={styles.menuIcon}
            />
          ) : (
            <Svg
              svg={searchIcon}
              alt="Search button"
              className={styles.searchIcon}
            />
          )}
        </div>
      )}
      {searchBarOpen ? (
        <div className={styles.searchInputWrapper}>
          <div className={styles.searchInputInner}>
            <SearchInput
              searchAsYouType={false}
              reset={`https://icon-library.net/images/svg-close-icon/svg-close-icon-27.jpg`}
            />
          </div>
          <div className={styles.searchPanelInner}>
            <SearchPanel />
          </div>
        </div>
      ) : (
        ``
      )}
    </div>
  );
};

MenuBarWithoutQuery.propTypes = {
  reportNavOpen: PropTypes.bool,
  mobileDevice: PropTypes.bool,
  searchBarOpen: PropTypes.bool,
  toggleReportNav: PropTypes.func,
  showIsMobile: PropTypes.func,
  toggleSearchBar: PropTypes.func,
  closeReportNav: PropTypes.func,
  currentPage: PropTypes.string,
  activePathname: PropTypes.string,
  data: PropTypes.shape({}),
  pathname: PropTypes.string
};

const MenuBar = props => (
  <StaticQuery
    query={graphql`
      {
        allSoetChapter {
          edges {
            node {
              entityId
              path
              title
              titleNumber
              next {
                path
              }
            }
          }
        }
        allSoetArticle {
          edges {
            node {
              entityId
              path
              title
              titleNumber
              next {
                path
              }
            }
          }
        }
      }
    `}
    render={data => <MenuBarWithoutQuery data={data} {...props} />}
  />
);

const provideContext = Component => props => {
  return (
    <NavContext.Consumer>
      {({
        activePathname,
        reportNavOpen,
        mobileDevice,
        searchBarOpen,
        showIsMobile,
        toggleReportNav,
        toggleSearchBar,
        closeReportNav
      }) => (
        <Component
          activePathname={activePathname}
          reportNavOpen={reportNavOpen}
          searchBarOpen={searchBarOpen}
          mobileDevice={mobileDevice}
          toggleSearchBar={toggleSearchBar}
          toggleReportNav={toggleReportNav}
          showIsMobile={showIsMobile}
          closeReportNav={closeReportNav}
          {...props}
        />
      )}
    </NavContext.Consumer>
  );
};

export default provideContext(MenuBar);
