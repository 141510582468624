import React from "react";
// import PropTypes from "prop-types";

import { Hits, connectStateResults } from "react-instantsearch-dom";
import CustomRefinementList from "../../CustomRefinementList";
import CustomClearRefinements from "../../ClearRefinements";

import styles from "./index.module.css";

const ResultsWrapper = connectStateResults(
  ({ searchState, searchResults, searching, hitComponent }) => {
    const hasResults = !!searchResults && searchResults.nbHits !== 0;
    const hasQuery = !!searchState && !!searchState.query;
    return (
      <React.Fragment>
        <div
          hidden={hasResults || (!hasResults && !hasQuery) || searching}
          className={styles.resultsEmpty}
        >
          <div className={styles.searchPanel}>
            <div className={styles.searchPanelPaddingInner}>
              <p>No results found for {searchState.query}</p>
              <br />
              <p>Maybe try a different keyword or your country name!</p>
            </div>
          </div>
        </div>
        <div hidden={!searching} className={styles.resultsSearching}>
          <span>Searching...</span>
        </div>
        <div
          className={styles.resultsContainer}
          hidden={!hasResults || (!hasQuery && hasResults)}
        >
          <div className={styles.searchPanel}>
            <div className={styles.searchFacets}>
              <p>Filter by: </p>
              <CustomRefinementList operator="or" attribute="facet" />
              <CustomClearRefinements clearsQuery />
            </div>
            <Hits hitComponent={hitComponent} />
          </div>
        </div>
      </React.Fragment>
    );
  }
);

export default ResultsWrapper;
