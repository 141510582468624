import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Img from "gatsby-image";

const Item = ({ type, imageImage, title, subTitle, link }) => {
  const imageStyles =
    type === `rounded` ? styles.itemImageRounded : styles.itemImage;

  let Image = ``;

  if (imageImage && imageImage.childImageSharp) {
    Image = (
      <Img
        className={imageStyles}
        alt={title}
        style={{ paddingBottom: 0 }}
        fluid={{ ...imageImage.childImageSharp.fluid, aspectRatio: 1 }}
      />
    );
  }

  return (
    <div className={styles.item}>
      {link && (
        <a href={link} target="_blank" rel="noopener noreferrer" title={title}>
          {Image}
        </a>
      )}
      {!link && <>{Image}</>}
      <h1 className={styles.itemTitle}>{title}</h1>
      <h2 className={styles.itemSubTitle}>{subTitle}</h2>
    </div>
  );
};

const BlockImageGrid = ({ title, insight, type, items }) => (
  <div className={styles.root}>
    {insight && <h1 className={styles.insight}>{insight}</h1>}
    {title && <h2 className={styles.title}>{title}</h2>}
    <div className={styles.items}>
      {items.length > 0 &&
        items.map((item, i) => <Item key={i} type={type} {...item} />)}
    </div>
  </div>
);

Item.defaultProps = {
  type: `square`
};

Item.propTypes = {
  // image: PropTypes.string.isRequired,
  imageImage: PropTypes.shape({}),
  subTitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf([`square`, `rounded`])
};

BlockImageGrid.defaultProps = {
  items: [],
  skipTitle: false
};

BlockImageGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(Item.propTypes)),
  title: PropTypes.string,
  insight: PropTypes.string,
  skipTitle: PropTypes.bool,
  type: Item.propTypes.type,
  link: PropTypes.string
};

export default BlockImageGrid;
