import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./index.module.css";
import { getConfig } from "../../utils/highchartsConfigurator";
import HighchartsReact from "highcharts-react-official";

let Highcharts;
let mapFactoryCalls = 0;
let treemapFactoryCalls = 0;
let exportingFactoryCalls = 0;
let exportDataFactoryCalls = 0;

// includes defaults & theme overrides
import "./highchartsThemeAtomico.css";

class Chart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: null,
      chartReady: false,
      errorFound: false,
      isMounted: false,
      chart: null
    };

    this.onChartReady = this.onChartReady.bind(this);
  }

  getConfig(opts) {
    const options = Object.assign(
      {},
      {
        disableDataLabels: opts.disableDataLabels,
        decimalPoints: opts.decimalPoints,
        title: opts.title,
        type: opts.type,
        yAxisLabel: opts.yAxisLabel,
        yAxisLabel2: opts.yAxisLabel2,
        xAxisLabel: opts.xAxisLabel,
        suffix: opts.suffix,
        prefix: opts.prefix,
        stacking: opts.stacking,
        showRegression: opts.showRegression,
        groupSeries: opts.groupSeries,
        groups: [opts.group],
        colorAxisRange: opts.colorAxisRange,
        lineSeriesPrefix: opts.lineSeriesPrefix,
        lineSeriesSuffix: opts.lineSeriesSuffix,
        lineSeriesDecimalPoints: opts.lineSeriesDecimalPoints
      }
    );

    // We only receive one group, but the configurator expects an array of
    // groups. We pass in a one element array of groups and the id of that
    // group as the 'selected' group.
    const selectedGroupId = opts.group.id;
    const config = getConfig(options, selectedGroupId);

    if (config.chart.type === `map`) {
      config.chart.map = require(`./highcharts-europe-data`).default;
    }

    return config;
  }

  onChartReady(chart) {
    const { chartReady } = this.state;
    if (!chartReady) {
      this.setState({ chartReady: true, chart });
      this.props.onChartRef(chart, this.state.config);
    }
  }

  componentDidMount() {
    // Setup highcharts now that we're definitely running in the browser
    // Specifically import /js/highcharts to enable CSS-based styling
    // See: https://www.highcharts.com/docs/chart-design-and-style/style-by-css
    Highcharts = require(`highcharts/js/highcharts`);
    const mapFactory = require(`highcharts/js/modules/map.js`);
    const treemapFactory = require(`highcharts/js/modules/treemap.js`);
    const exportingFactory = require(`highcharts/js/modules/exporting.js`);
    const exportDataFactory = require(`highcharts/js/modules/export-data.js`);

    // init highmaps with highcharts once and once only to prevent errors when
    // loading multiple charts
    if (mapFactoryCalls === 0) {
      mapFactoryCalls = 1;
      mapFactory(Highcharts);
    }

    // init treemap module with highcharts once and once only to prevent errors
    // when loading multiple charts
    if (treemapFactoryCalls === 0) {
      treemapFactoryCalls = 1;
      treemapFactory(Highcharts);
    }

    // init exportingFactory with highcharts once and once only to prevent
    // errors when loading multiple charts
    if (exportingFactoryCalls === 0) {
      exportingFactoryCalls = 1;
      exportingFactory(Highcharts);
    }

    // init exportDataFactory with highcharts once and once only to prevent
    // errors when loading multiple charts
    if (exportDataFactoryCalls === 0) {
      exportDataFactoryCalls = 1;
      exportDataFactory(Highcharts);
    }

    // The `lang` attribute is global and can't be set within the config...
    Highcharts.setOptions({
      lang: {
        thousandsSep: `,`
      }
    });

    this.setState({
      config: this.getConfig(this.props),
      isMounted: true
    });
    // this.timeout = window.setTimeout(() => {
    // why was this setState wrapped in a timeout?
    // }, 200);
  }

  /**
   * Override theme CSS to hide or show the y axis line based on the yAxisLabel
   * prop. We're using Highcharts in styled mode so can't change this via the
   * Highcharts api.
   */
  componentDidUpdate(prevProps) {
    // const chartRef = this.chartRef;
    // const yAxisLabel = this.props.yAxisLabel;
    // if (chartRef) {
    //   const axisLine = get(".chart.yAxis[0].axisLine", chartRef);
    //   if (axisLine) {
    //     // not all charts have an axisLine
    //     chartRef.chart.yAxis[0].axisLine.element.style.strokeWidth = yAxisLabel
    //       ? 1
    //       : 0;
    //   }
    // }
    if (prevProps.group !== this.props.group) {
      this.setState({ config: this.getConfig(this.props) });
    }
  }

  componentDidCatch(/* error, info */) {
    this.setState({ errorFound: true });
    // console.log(`Error found`, error, info);
  }

  errorMessage() {
    return (
      <p style={{ color: `black`, padding: `10px` }}>
        There was an error displaying this chart.
      </p>
    );
  }

  render() {
    const { isMounted, chartReady, errorFound, config } = this.state;
    const chartType = this.props.type;
    return (
      <div className={styles.root}>
        {errorFound ? (
          this.errorMessage()
        ) : (
          <div
            className={
              chartReady ? styles.chartWrapperReady : styles.chartWrapper
            }
          >
            {isMounted && config && (
              <HighchartsReact
                options={config}
                highcharts={Highcharts}
                constructorType={chartType === `map` ? `mapChart` : `chart`}
                callback={this.onChartReady}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

Chart.defaultProps = {
  skipTitle: false,
  type: `column`,
  stacking: `none`,
  yAxisLabel: ``,
  yAxisLabel2: ``,
  prefix: ``,
  suffix: ``,
  onChartRef: () => {}
};

Chart.propTypes = {
  disableDataLabels: PropTypes.bool,
  decimalPoints: PropTypes.number,
  title: PropTypes.string,
  skipTitle: PropTypes.bool,
  type: PropTypes.oneOf([
    `area`,
    `bar`,
    `bubble`,
    `column_line`,
    `column`,
    `heatmap`,
    `line`,
    `map`,
    `pie`,
    `scatter`,
    `treemap`,
    `table`
  ]),
  stacking: PropTypes.oneOf([`none`, `normal`, `percent`]),
  yAxisLabel: PropTypes.string,
  yAxisLabel2: PropTypes.string,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  onDownloadClick: PropTypes.func,
  colour_axis_range: PropTypes.array,
  // Highcharts data
  group: PropTypes.shape({
    id: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.array, // numbers or objects
        stack: PropTypes.string
      })
    )
  }),
  onChartRef: PropTypes.func
};

export default Chart;
