import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import FigureFooter from "../FigureFooter";
import get from "lodash/get";

const BlockChartImage = ({ insight, title, image, source, note, svg }) => {
  /* NOTE: a load of the markup / styles in this component are copy and pasted from the BlockChart component */
  /* this is likely to be awkward and annoying for anyone that wants to change this stuff and realises it's in two places */
  /* sorry :( */
  /* it's 11pm the day before the site launches so this is what it's gonna be */
  let src = false;
  if (
    svg &&
    get(svg, `publicURL`) &&
    get(svg, `extension`) &&
    svg.extension === `svg`
  ) {
    src = svg.publicURL;
  } else if (image) {
    src = image;
  }
  if (!src) return;

  return (
    <div className={styles.root}>
      <div className={styles.insightWrapper}>
        {insight && <h2 className={styles.insightLarge}>{insight}</h2>}
      </div>
      <div className={styles.grid}>
        <div className={styles.chartHeader}>
          {title && <h2 className={styles.title}>{title}</h2>}
        </div>
        <img className={styles.figure} alt={title} src={src} />
        <div className={styles.sourceShare}>
          <FigureFooter sources={[source]} note={note} />
        </div>
        <div className={styles.legend}></div>
        {note && (
          <div className={styles.noteWrapper}>
            <dl className={styles.note}>
              <dt className={styles.term}>Note:</dt>
              <dd className={styles.definition}>{note}</dd>
            </dl>
          </div>
        )}
      </div>
    </div>
  );
};

BlockChartImage.propTypes = {
  title: PropTypes.string,
  insight: PropTypes.string,
  source: PropTypes.string,
  note: PropTypes.string,
  image: PropTypes.string,
  svg: PropTypes.string
};

export default BlockChartImage;
