import React, { Component } from "react";
import PropTypes from "prop-types";
import slugify from "slugify";
import styles from "./index.module.css";
import get from "lodash/get";

import Chart from "../Chart";
import Table from "../Table";
import SelectControl from "../SelectControl";
import FigureFooter from "../FigureFooter";
import Insight from "../Insight";
import ChartLegend from "../ChartLegend";
import queryString from "query-string";
import ViewableMonitor from "../ViewableMonitor";

/**
 * Wrapper around charts and tables. Provides switching, download and sharing
 * functionality.
 */

const getChartIdFromHash = (hash, parse) => {
  const hashObj = parse(location.hash);
  const chartHash = Object.keys(hashObj).find(key => key.startsWith(`chart-`));
  if (!chartHash) return;
  const parts = chartHash.split(`-`);
  return parts[1];
};

// TODO: this component is a monster
class BlockChart extends Component {
  constructor(props) {
    super(props);

    const selectedGroupId =
      props.initialGroupId || get(props, `props.groups[0].id`, 0);

    this.state = { selectedGroupId };
    this.onGroupChange = this.onGroupChange.bind(this);
    this.onChartRef = this.onChartRef.bind(this);
    this.onSeriesToggle = this.onSeriesToggle.bind(this);
  }

  componentDidMount() {
    // handle links directly to a specific chart and group, like "#chart-5?cgid=17"
    // by selecting the correct group for the given chart
    const location = window && window.location;
    const chartId = parseInt(
      getChartIdFromHash(location.hash, queryString.parse),
      10
    );
    const groupId = parseInt(queryString.parse(location.search).cgid, 10);
    if (
      Number.isInteger(chartId) &&
      Number.isInteger(groupId) &&
      chartId === this.props.chartId
    ) {
      this.setState({ selectedGroupId: groupId });
    }
  }

  // Recreate the charts config using the newly selected group
  onGroupChange(event) {
    const selectedGroupId = parseInt(event.target.value, 10);
    this.setState({ selectedGroupId });
  }

  onChartRef(chartRef, config) {
    this.setState({ chartRef, config });
  }

  onSeriesToggle(index) {
    const chart = this.state.chartRef;

    if (chart.series.length == 1) {
      // Don't hide the series if its the only one
      return false;
    }

    if (chart && index > -1 && index < chart.series.length) {
      const series = chart.series[index];
      if (series.visible) {
        series.hide();
      } else {
        series.show();
      }
    }
  }

  render() {
    const {
      chartId,
      type,
      title,
      insight,
      insightBlock,
      groups,
      onDownloadClick,
      sources,
      note,
      disableLegend,
      selectableGroups,
      titleNumber,
      groupSeries,
      width,
      disableDataDownload,
      showWatermark
    } = this.props;
    // Can't have a chart without any groups
    if (!groups || groups.length === 0) return <div className={styles.root} />;

    const selectedGroupId = this.state.selectedGroupId;
    let selectedGroup = groups.find(group => group.id === selectedGroupId);
    if (!selectedGroup) selectedGroup = groups[0];
    const isTable = type === `table`;
    const isChart = type !== `table`;
    const Figure = isTable ? Table : Chart;
    const slug = title ? slugify(title) : ``;
    const showLegend = isChart && !disableLegend;
    const multipleGroups = groups.length > 1;
    // show insight if all required values exist in insightBlock
    const showInsightBlock =
      insightBlock &&
      [`title`, `description`, `stat`].every(k => insightBlock[k]);

    let insightStyle = styles.insight;
    if (!showInsightBlock && insight && insight.length > 140) {
      insightStyle = styles.insightLarge;
    }

    let legendItems = selectedGroup.series;
    let axisColors = false;

    // Some chart types use a different range of colours
    // Generate a legend for them
    if (this.state.config && this.state.config.legendItems) {
      legendItems = this.state.config.legendItems;
    }

    if ([`map`, `heatmap`, `treemap`].indexOf(type) > -1) {
      axisColors = true;
    }

    let legendStyle = styles.legend;
    if (legendItems.length >= 12 && !isTable) {
      legendStyle = styles.legendLong;
    }

    let gridStyle = styles.grid;
    if (width === `med`) {
      gridStyle = styles.gridMed;
    } else if (width === `small`) {
      gridStyle = styles.gridSmall;
    } else if (width == `print`) {
      gridStyle = styles.gridPrint;
    }

    let figureDropdown = ``;
    if (multipleGroups) {
      figureDropdown = styles.figure;
    } else {
      figureDropdown = styles.figureHasDropdown;
    }

    return (
      <div className={styles.root} id={`chart-${chartId}`}>
        <div className={styles.insightWrapper}>
          {insight && <h2 className={insightStyle}>{insight}</h2>}
          {showInsightBlock && (
            <div className={styles.insightBlock}>
              <Insight
                title={insightBlock.title}
                stat={insightBlock.stat}
                description={insightBlock.description}
                inverted={true}
              />
            </div>
          )}
        </div>
        <div className={gridStyle}>
          <div className={styles.chartHeader}>
            {title && (
              <h2 id={slug} className={styles.title}>
                {title}
              </h2>
            )}
          </div>

          {multipleGroups && (
            <div className={styles.select}>
              <SelectControl
                label="Dataset"
                size="small"
                values={groups}
                selectedId={selectedGroupId}
                onChange={this.onGroupChange}
                selectableValues={selectableGroups}
              />
            </div>
          )}

          <div className={figureDropdown}>
            <ViewableMonitor>
              {isViewable =>
                isViewable && (
                  <Figure
                    {...this.props}
                    groups={null}
                    group={selectedGroup}
                    onChartRef={this.onChartRef}
                  />
                )
              }
            </ViewableMonitor>
          </div>

          <div className={styles.sourceShare}>
            <FigureFooter
              onDownloadClick={
                onDownloadClick
                  ? () =>
                      onDownloadClick(
                        chartId,
                        selectedGroup.id,
                        this.state.chartRef,
                        titleNumber,
                        disableDataDownload
                      )
                  : null
              }
              sources={sources}
              chartId={chartId}
              groupId={selectedGroup.id}
              title={title}
              slug={slug}
              showWatermark={showWatermark}
            />
          </div>
          <div className={legendStyle}>
            {showLegend && (
              <ChartLegend
                series={legendItems}
                axis={axisColors}
                grouped={groupSeries}
                onSeriesToggle={this.onSeriesToggle}
                chartRef={this.state.chartRef}
              />
            )}
          </div>
          {note && (
            <div className={styles.noteWrapper}>
              <dl className={styles.note}>
                <dt className={styles.term}>Note:</dt>
                <dd className={styles.definition}>{note}</dd>
              </dl>
            </div>
          )}
        </div>
      </div>
    );
  }
}

BlockChart.defaultProps = {
  groups: [],
  selectableGroups: true,
  showWatermark: false
};

BlockChart.propTypes = {
  title: PropTypes.string,
  insightTitle: PropTypes.string,
  insightStat: PropTypes.string,
  insightDescription: PropTypes.string,
  type: PropTypes.oneOf([
    `area`,
    `bar`,
    `bubble`,
    `column_line`,
    `column`,
    `heatmap`,
    `line`,
    `map`,
    `pie`,
    `scatter`,
    `treemap`,
    `table`
  ]),
  onDownloadClick: PropTypes.func,
  chartId: PropTypes.number,
  sources: PropTypes.arrayOf(PropTypes.string),
  note: PropTypes.string,
  disableLegend: PropTypes.bool,
  groupSeries: PropTypes.bool,
  showRegression: PropTypes.bool,
  disableDataDownload: PropTypes.bool,
  tableWrap: PropTypes.bool,
  // Highcharts data
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      categories: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      series: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          data: PropTypes.array, // numbers or objects
          stack: PropTypes.string
        })
      )
    })
  ),
  selectableGroups: PropTypes.bool
};

export default BlockChart;
