// eslint-disable-next-line import/no-unresolved
import { connect } from "react-redux";
import NavActions from "./";

import { requestReportDownload } from "../../../state/download/report";

const withRedux = connect(
  () => ({}),
  dispatch => ({
    onDownloadClick() {
      dispatch(requestReportDownload());
    }
  })
);

export default withRedux(NavActions);
