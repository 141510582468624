import React from "react";

// See wrap-with-context plugin for implementation of this provider.
// Done as a separate plugin to prevent multiple instances getting loaded
// when PageRenderer does infinite article loading

export default React.createContext({
  activePathname: null,
  setActivePathname: () => {},
  reportNavOpen: false,
  toggleReportNav: () => {},
  toggleSearchBar: () => {},
  closeReportNav: () => {}
});
