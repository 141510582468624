import React from "react";
import styles from "./index.module.css";
import { connectCurrentRefinements } from "react-instantsearch-dom";

import Svg from "../Svg";

import CloseIcon from "./menuIconClose.svg";

const ClearRefinements = ({ items, refine }) => (
  <button
    className={styles.closeButton}
    onClick={() => refine(items)}
    disabled={!items.length}
  >
    <Svg svg={CloseIcon} alt="close button" className={styles.closeIcon} />
  </button>
);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

export default CustomClearRefinements;
