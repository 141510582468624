import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";

import SplashContainer from "../Splash/container";
import DataPartnersContainer from "../DataPartners/container";
import Seo from "../Seo";
// import KeyFindingsContents from "../KeyFindingsContents";
// import KeyFindings from "../KeyFindings";
// import CountryHighlights from "../CountryHighlights";
import Footer from "../Footer";
// import keyFindingsData from "./key-findings-data.json";
// import countryHighlightsData from "./country-highlights-data.json";
import styles from "./index.module.css";

export class HomeWithoutStaticQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerOnboarding: false
    };
    this.closeOnboarding = this.closeOnboarding.bind(this);
    this.openOnboarding = this.openOnboarding.bind(this);
  }

  closeOnboarding() {
    this.setState({ triggerOnboarding: false });
  }

  openOnboarding() {
    this.setState({ triggerOnboarding: true });
  }
  render() {
    const { reportPath } = this.props;
    return (
      <div className={styles.root}>
        <Seo />
        <SplashContainer reportPath={reportPath} />
        <DataPartnersContainer />
        {/* <KeyFindingsContents titles={keyFindingsData.map(item => item.title)} /> */}
        {/* <KeyFindings data={keyFindingsData} /> */}
        {/* <CountryHighlights index={keyFindingsData.length + 1} data={countryHighlightsData} /> */}
        <Footer
          toggleOnboarding={this.openOnboarding}
          reportPath={reportPath}
        />
      </div>
    );
  }
}

HomeWithoutStaticQuery.propTypes = {
  reportPath: PropTypes.string
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        soetChapter {
          path
        }
      }
    `}
    render={data => (
      <HomeWithoutStaticQuery reportPath={data.soetChapter.path} {...props} />
    )}
  />
);
