import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "./index.module.css";
import { ShareLinkedIn, ShareTwitter } from "../Share";
import copylink from "./copylink.svg";
import Svg from "../Svg";

export class ArticleFooterWithoutStaticQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { copied: false };
  }

  render() {
    const { title, siteUrl, path } = this.props;
    const shareUrl = `${siteUrl}${path}`;

    const clearState = () => {
      this.setState(prevState => ({
        copied: !prevState.copied
      }));
    };

    return (
      <div className={styles.root}>
        <div className={styles.footerActions}>
          <p className={styles.shareText}>Share this article</p>
          <div className={styles.shareIcons}>
            <div className={styles.shareIcon}>
              <ShareLinkedIn
                className={styles.shareIcon}
                url={`${siteUrl}`}
                sourceUrl={shareUrl}
                title={title}
                large={true}
              />
            </div>
            <div className={styles.shareIcon}>
              <ShareTwitter text={title} url={shareUrl} large={true} />
            </div>
            <div className={styles.shareIcon}>
              <div
                className={
                  styles.shareNotification +
                  (this.state.copied ? ` ` + styles.show : ` ` + styles.hide)
                }
                onClick={clearState}
              >
                <p className={styles.shareNotificationText}>Link copied!</p>
              </div>
              <CopyToClipboard
                text={shareUrl}
                onCopy={() => this.setState({ copied: true })}
              >
                <a className={styles.rootCopy}>
                  <Svg className={styles.rootCopyIcon} svg={copylink} />
                </a>
              </CopyToClipboard>
            </div>
          </div>
          <div className={styles.shareLine}></div>
        </div>
      </div>
    );
  }
}

ArticleFooterWithoutStaticQuery.propTypes = {
  siteUrl: PropTypes.string,
  path: PropTypes.string
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl: url
          }
        }
      }
    `}
    render={data => (
      <ArticleFooterWithoutStaticQuery
        siteUrl={data.site.siteMetadata.siteUrl}
        {...props}
      />
    )}
  />
);
