import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import BgQuoteLeftIcon from "./bgQuoteLeft.svg";
import BgQuoteRightIcon from "./bgQuoteRight.svg";
import BgQuoteLeftLargeIcon from "./bgQuoteLeftLarge.svg";
import Img from "gatsby-image";

const BlockQuote = ({
  text,
  name,
  jobTitle,
  company,
  extract,
  headShotImage,
  style
}) => {
  const attributionStyle = extract ? styles.attribution : styles.attributionTop;

  // set base styles here
  // TODO: can we make this better?

  let quoteTemplates = styles.blockquote;
  let mainQuoteStyles = styles.mainQuote;
  let extractTextStyle = styles.quoteExtractText;
  if (style == `no_excerpt`) {
    quoteTemplates = styles.blockquoteNoExcerpt;
    mainQuoteStyles = styles.mainQuoteNoExcerpt;
    extractTextStyle = styles.quoteExtractTextNoExcerpt;
  } else if (style == `excerpt_only`) {
    quoteTemplates = styles.blockquoteExcerptOnly;
    mainQuoteStyles = styles.mainQuoteExcerptOnly;
    extractTextStyle = styles.quoteExtractTextExcerptOnly;
  } else if (style == `with_excerpt`) {
    quoteTemplates = styles.blockquoteWithExcerpt;
    mainQuoteStyles = styles.quoteWithExcerpt;
    extractTextStyle = styles.quoteExtractTextWithExcerpt;
  }

  return (
    <div className={styles.root}>
      <blockquote className={quoteTemplates}>
        <header className={styles.quoteExtract}>
          <BgQuoteLeftLargeIcon />
          <p className={extractTextStyle}>{extract}</p>
        </header>

        {text && (
          <div className={mainQuoteStyles}>
            <div hidden className={styles.mainQuoteLeftIcon}>
              <BgQuoteLeftIcon />
            </div>
            <div
              className={styles.mainQuoteText}
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
            <div hidden className={styles.mainQuoteRightIcon}>
              <BgQuoteRightIcon />
            </div>
          </div>
        )}

        <footer className={attributionStyle}>
          <cite className={styles.cite}>
            {headShotImage && headShotImage.childImageSharp && (
              <Img
                fixed={headShotImage.childImageSharp.fixed}
                className={styles.headShot}
              />
            )}
            <div className={styles.author}>
              <p className={styles.name}>{name}</p>
              {company && <p className={styles.company}>{company}</p>}
              {jobTitle && <p className={styles.jobTitle}>{jobTitle}</p>}
            </div>
          </cite>
        </footer>
      </blockquote>
    </div>
  );
};

BlockQuote.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string,
  extract: PropTypes.string,
  jobTitle: PropTypes.string,
  company: PropTypes.string,
  headShot: PropTypes.string,
  headShotImage: PropTypes.shape({})
};

export default BlockQuote;
