import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "../components/Seo";

import Article from "../components/Article";
import ArticleFooter from "../components/ArticleFooter";
import ReportLayout from "../components/ReportLayout";
import InfiniteArticles from "../components/InfiniteArticles";

class ArticleTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const article = data.soetArticle;
    const nextMap = data.allSoetArticle.edges.reduce((map, item) => {
      map[item.node.entityId] = { next: item.node.next, path: item.node.path };
      return map;
    }, {});

    return (
      <ReportLayout
        title={article.title}
        path={article.path}
        entityId={article.entityId}
      >
        <InfiniteArticles
          FooterComponent={ArticleFooter}
          article={article}
          nextMap={nextMap}
        >
          <Seo
            title={article.seo_title}
            description={article.subTitle || null}
            path={article.path}
          />
          <Article
            path={article.path}
            entityId={article.entityId}
            articleType={article.type}
            titleNumber={article.titleNumber}
            title={article.title}
            seoTitle={article.title}
            sources={article.sources}
            blocks={article.blocks}
            next={article.next}
          />
        </InfiniteArticles>
      </ReportLayout>
    );
  }
}
ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    soetArticle: PropTypes.shape({
      chapter: PropTypes.shape({
        title: PropTypes.string
      }),
      seoTitle: PropTypes.string,
      sources: PropTypes.arrayOf(PropTypes.string)
    })
  })
};

export default ArticleTemplate;

export const ChartBlockFragment = graphql`
  fragment ChartBlockFragment on ChartBlock {
    entityType
    priority
    chartId
    titleNumber
    disableDataLabels
    disableLegend
    decimalPoints
    insight
    insightBlock {
      title
      stat
      description
    }
    width
    note
    prefix
    sources
    stacking
    suffix
    title
    type
    yAxisLabel
    yAxisLabel2
    xAxisLabel
    groupSeries
    showRegression
    colorAxisRange
    disableDataDownload
    lineSeriesPrefix
    lineSeriesSuffix
    lineSeriesDecimalPoints
    tableWrap
    groups {
      id
      name
      categories
      series {
        name
        data
        type
        yAxis
        zIndex
        stack
        yAxisLabels
        layoutAlgorithm
      }
    }
  }
`;

// eslint-disable-next-line no-undef
export const ArticleFieldsFragment = graphql`
  fragment ArticleFieldsFragment on soetArticle {
    entityId
    path
    pathStandalone
    next {
      entityId
      path
      pathStandalone
      title
      titleNumber
      type
    }
    sources
    title
    seo_title
    titleNumber
    type
    soetChapter {
      entityId
    }
    blocks {
      ...ChartBlockFragment
      ... on FreeTextBlock {
        entityType
        priority
        title
        text
        subTitle
        addKeyLine
      }
      ... on ImageBlock {
        entityType
        priority
        imageImage {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1680) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
        type
      }
      ... on ChartImageBlock {
        id
        svg {
          publicURL
          extension
        }
        title
        note
        insight
        entityType
        source
        priority
      }
      ... on ImageTextBlock {
        entityType
        priority
        imageImage {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1680) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        subTitle
        text
        style
      }
      ... on ImageGridBlock {
        entityType
        priority
        title
        insight
        type
        items {
          image
          subTitle
          title
          link
          imageImage {
            childImageSharp {
              fluid(quality: 80, maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      ... on InsightBlock {
        entityType
        priority
        title
        items {
          title
          stat
          source
          description
        }
      }
      ... on QuoteBlock {
        entityType
        priority
        company
        jobTitle
        name
        text
        extract
        headShotImage {
          childImageSharp {
            fixed(quality: 80, width: 110, height: 110) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        style
      }
    }
  }
`;

// 2017 blocks that are unused
// ... on ChartImageBlock {
//   type
//   priority
//   content {
//     image
//     insight
//     title
//     note
//     sources
//     svg
//   }
// }

// 2017 blocks that were removed...
// ... on SpacerBlock {
//   type
//   priority
//   content {
//     dark
//   }
// }
// ... on VideoBlock {
//   type
//   priority
//   content {
//     id
//     url
//   }
// }

/**
 * We want to get:
 *
 * - current article
 * - info about next article for each article (used in infinite scrolling)
 */
// eslint-disable-next-line no-undef
export const articleQuery = graphql`
  query ArticleQuery($entityId: Int!) {
    soetArticle(entityId: { eq: $entityId }) {
      id
      ...ArticleFieldsFragment
    }
    allSoetArticle {
      edges {
        node {
          path
          entityId
          next {
            entityId
            path
            pathStandalone
            title
            titleNumber
            type
          }
        }
      }
    }
  }
`;
