import React from "react";
// import PropTypes from "prop-types";
import { connectSearchBox } from "react-instantsearch-dom";
import debounce from "lodash/debounce";
import searchIcon from "./searchIcon.svg";
import Svg from "../Svg";
import analytics from "../../utils/analytics";

import styles from "./index.module.css";

// const CloseContainer = connectStateResults(
//   ({ searchState, searchResults, searching, hitComponent }) => {
//     const hasResults = !!searchResults && searchResults.nbHits !== 0;
//     const hasQuery = !!searchState && !!searchState.query;

//     return (
//       <React.Fragment>
//         {hasResults && hasQuery && <div className={styles.clickArea}></div>}
//       </React.Fragment>
//     );
//   }
// );

const SearchInput = connectSearchBox(({ refine }) => {
  const debouncedSearch = debounce(e => refine(e.target.value), 300);
  const debouncedAnalytics = debounce(analytics, 300);
  const onChange = e => {
    e.persist();
    debouncedSearch(e, e.eventTarget);
    debouncedAnalytics(`event`, `search`, {
      event_category: `search`,
      event_label: `searchTerm`,
      event_value: e.target.value
    });
  };

  // const onClickClear = ev => {
  //   refine(``);
  //   ev.stopPropagation();
  //   ev.currentTarget.classList.add(`isClosed`);
  // };

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <label className={styles.label}>
          <input
            className={styles.input}
            placeholder="Search here..."
            type="search"
            onChange={onChange}
          />
          <Svg svg={searchIcon} alt="Search" className={styles.icon} />
        </label>
      </div>
    </div>
  );
});

export default SearchInput;
