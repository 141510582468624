import React from "react";
import PropTypes from "prop-types";

import Svg from "../Svg";
import PrimaryButton from "../PrimaryButton";
import LogoBlock from "../LogoBlock";
import styles from "./index.module.css";
import illustrationBlock from "./illustration_block.svg";

const Splash = ({ reportPath, onDownloadClick }) => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <div className={styles.lockup}>
        <LogoBlock />
        <div className={styles.header}>
          <h1 className={styles.title}>The State of European Tech</h1>
        </div>
        <div className={styles.text}>
          <p className={styles.textParagraphBold}>
            Welcome to the most comprehensive data-driven analysis of European
            technology
          </p>
        </div>

        <nav className={styles.nav}>
          <div className={styles.navItem}>
            <PrimaryButton to={reportPath} type="link" teal>
              Read report
            </PrimaryButton>
          </div>
        </nav>
      </div>
    </div>
    <div className={styles.map}>
      <Svg className={styles.illustrationBlock} svg={illustrationBlock} />
    </div>
  </div>
);

Splash.propTypes = {
  reportPath: PropTypes.string,
  onDownloadClick: PropTypes.func
};

export default Splash;
