// eslint-disable-next-line import/default
import Highcharts from "highcharts";
import findIndex from "lodash/findIndex";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import regression from "regression";

/**
 * Given a set of highcharts data and some config options, return a valid
 * Highcharts config.
 */
const CHART_TYPES = [
  `area`,
  `bar`,
  `column`,
  `line`,
  `pie`,
  `column_line`,
  `scatter`
];

const defaultConfig = {
  chart: { type: `bar`, colorCount: 15, height: 350 },
  title: false,
  plotOptions: {
    area: {
      stacking: false,
      dataLabels: {
        padding: 2,
        enabled: true
      }
    },
    bar: {
      stacking: false,
      dataLabels: {
        padding: 2,
        enabled: true
      }
    },
    column: {
      stacking: false,
      dataLabels: {
        padding: 2,
        enabled: true
      }
    },
    line: {
      dataLabels: {
        padding: 2,
        enabled: true
      },
      zIndex: 100
    },
    pie: {
      dataLabels: {
        padding: 2,
        enabled: true
      }
    },
    series: {
      animation: false, // for screenshots
      pointPadding: 0.08,
      groupPadding: 0.06,
      dataLabels: {
        padding: 2,
        enabled: true,
        allowOverlap: true
      }
    },
    map: {
      allAreas: true,
      joinBy: [`iso-a2`, `code`],
      dataLabels: {
        enabled: false
      },
      tooltip: {
        headerFormat: ``,
        pointFormat: `{point.name}: <b>{point.labelText}</b>`
      }
    },
    heatmap: {
      dataLabels: {
        enabled: false
      },
      tooltip: {
        headerFormat: ``,
        pointFormat: `<b>{point.labelText}:</b> {point.valueDecimals}`
      }
    },
    scatter: {
      dataLabels: {
        enabled: false
      }
    },
    bubble: {
      dataLabels: {
        enabled: false
      },
      tooltip: {
        headerFormat: `<b>{point.labelText}</b><br>`,
        pointFormat: `{point.labelText}: x: {point.x}, y: {point.y}, z: {point.z}`
      }
    },
    treemap: {
      dataLabels: {
        enabled: false,
        format: `{point.name}`
      },
      tooltip: {
        headerFormat: ``,
        pointFormat: `{point.name} {point.value}`
      }
    }
  },
  navigation: {
    buttonOptions: {
      enabled: false
    }
  },
  legend: {
    enabled: false
  },
  yAxis: {
    endOnTick: false,
    title: { text: `` },
    visible: true,
    lineWidth: 10,
    maxPadding: 0.15,
    labels: {
      distance: -20,
      padding: 2,
      enabled: false,
      autoRotation: [-45, -90],
      x: -5
    },
    reversedStacks: false
  },
  series: [],
  xAxis: {
    categories: [],
    labels: {
      distance: -20,
      padding: 2,
      autoRotation: [-45, -90],
      y: 16,
      style: {
        textOverflow: `none`
      }
    }
  },
  tooltip: {},
  credits: { enabled: false }
};

const toDecimalPlaces = function(num, places) {
  if (typeof num === `string` || num === null) {
    return num;
  }

  // Round ONLY when 0 decimal places
  if (places === 0) {
    num = Math.round(num);
    return num;
  }

  // If passed an use toFixed to return
  if (Number.isInteger(num)) {
    return num.toFixed(places);
  }

  // Set value to decimal places without rounding.
  places = places || -1;
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${places}})?`);
  return num.toString().match(re)[0];
};

const getDataClasses = function(data, decimalPoints) {
  // Find the highest and lowest value in a series and generate a range with 10
  // evenly spaced stops between them.
  // Expects series data to be array of objects with `value` property
  // Generate `name` for legend display based on `to` value
  const max = maxBy(data, `value`).value;
  const min = minBy(data, `value`).value;
  const diff = max - min;
  const steps = 10;
  const step = diff / steps;
  const dataClasses = [];

  for (let i = steps; i > 0; i--) {
    const to = i * step;
    dataClasses.push(to);
  }

  return formatDataClasseSteps(dataClasses, decimalPoints);
};

const formatDataClasseSteps = function(dataClasses, decimalPoints) {
  return dataClasses.map((item, i) => {
    const toFormatted = formatNumber(item, decimalPoints);
    const fromFormatted =
      i > 0 && i < dataClasses.length - 1
        ? formatNumber(dataClasses[i + 1], decimalPoints) + ` to `
        : `up to `;
    return {
      to: item,
      name: `${fromFormatted}${toFormatted}`
    };
  });
};

export const formatNumber = function(value, decimalPoints) {
  // Call toDecimalPlaces first to control rounding.
  // Highcharts.numberFormat might be OK for all but need to check requirements
  return Highcharts.numberFormat(
    toDecimalPlaces(value, decimalPoints),
    decimalPoints,
    `.`,
    `,`
  );
};

export const formatTableValue = function(value, decimalPoints) {
  const numeric = !isNaN(parseFloat(value)) && isFinite(value);
  const valueFormatted = numeric ? formatNumber(value, decimalPoints) : value;
  return valueFormatted.replace(`§`, ``);
};

export const getConfig = function(options, groupId) {
  const type = options.type || CHART_TYPES[0];

  // highcharts expects stacking to be one of: undefined/false or `percent` or `normal`
  // the backend gives us one of: `none`, `percent` or `normal`
  // so we convert `none` to false to keep Highcharts happy
  const stacking =
    options.stacking == `none` ? false : options.stacking || false;
  const yAxisLabel = options.yAxisLabel || ``;
  const xAxisLabel = options.xAxisLabel || ``;
  const showRegression = options.showRegression || false;
  const suffix = options.suffix || ``;
  const prefix = options.prefix || ``;
  const groups = options.groups || [];
  const decimalPoints = options.decimalPoints || 0;
  const lineSeriesDecimalPoints =
    options.lineSeriesDecimalPoints || decimalPoints;
  const lineSeriesSuffix = options.lineSeriesSuffix || ``;
  const lineSeriesPrefix = options.lineSeriesPrefix || ``;

  // No labels under 400px
  if (window.innerWidth <= 400) {
    options.disableDataLabels = true;
  }

  const currentGroup = groups.find(group => group.id === groupId);

  // Poor man's deep copy
  const configCopy = JSON.parse(JSON.stringify(defaultConfig));

  // Assign options to default config
  const config = Object.assign({}, configCopy, {
    series: currentGroup.series
  });

  config.chart.type = type;
  config.xAxis.categories = currentGroup.categories;
  config.yAxis.title.text = yAxisLabel;
  config.yAxis.labels.enabled = !!yAxisLabel;
  config.plotOptions.area.stacking = stacking;
  config.plotOptions.bar.stacking = stacking;
  config.plotOptions.column.stacking = stacking;
  config.yAxis.labels.formatter = function() {
    return formatNumber(this.value, decimalPoints);
  };
  config.plotOptions.series.dataLabels.formatter = function() {
    if (this.point.labelText == ``) {
      return this.point.labelText;
    } else {
      return `${prefix}${this.point.labelText}${suffix}`;
    }
  };

  if (stacking) {
    config.tooltip.shared = true;

    if (config.series.length >= 3) {
      config.plotOptions.area.dataLabels.enabled = false;
      config.plotOptions.bar.dataLabels.enabled = false;
      config.plotOptions.column.dataLabels.enabled = false;
      config.plotOptions.series.dataLabels.enabled = false;
    }
  }

  // Tooltip formatting
  if (options.stacking !== `none`) {
    config.tooltip.formatter = function() {
      let html = ``;
      this.points.forEach(point => {
        html = html + `<span class="highcharts-color-${point.colorIndex}">`;
        html = html + `\u25CF</span> ${point.series.name}: `;
        html = html + `<b>${prefix}${point.point.labelText}${suffix}</b><br/>`;
      });
      return html;
    };
  } else {
    config.tooltip.formatter = function() {
      let html = `<span class="highcharts-color-${this.point.colorIndex}">`;
      html = html + `\u25CF</span> ${this.series.name} `;
      html = html + `<b>${prefix}${this.point.labelText}${suffix}</b><br/>`;
      return html;
    };
  }

  if (options.type === `bar`) {
    // reset our default xAxis y label position (xAxis is vertical on bar charts)
    config.xAxis.labels.y = null;

    // Change aspect ratio to give extra height to bar charts with a lot of categories
    if (currentGroup.categories.length >= 12) config.chart.height = `100%`; // 1:1 aspect ratio
  }

  if (options.type === `line`) {
    config.series = config.series.map((serie, index) => {
      // Set proper padding on the data labels
      serie.dataLabels = { y: -8 };
      return serie;
    });
  }

  if (options.type === `column_line`) {
    // Delete existing chart type, type is specified within the series
    delete config.chart.type;

    // Setup second axis and axis labels
    const yAxis = Object.assign({}, config.yAxis);
    const yAxisDeepCopy = JSON.parse(JSON.stringify(config.yAxis)); // Note that this will fail for any unserializable fields
    const yAxis2 = Object.assign({}, yAxisDeepCopy, {
      opposite: true, // put this axis on the other side
      labels: { x: 1 } // undo negative x, so labels don't overlap the axis
    });
    yAxis2.labels.formatter = function() {
      return formatNumber(this.value, lineSeriesDecimalPoints);
    };
    yAxis2.title.text = options.yAxisLabel2;
    config.yAxis = [yAxis, yAxis2];

    config.series = config.series.map(serie => {
      if (serie.type === `line`) {
        // For the first series (the line) set a custom colour and zIndex
        serie.colorIndex = 16;
        // Set custom options for the line data labels
        serie.dataLabels = {
          padding: 4,
          y: -8,
          formatter: function() {
            const pointLabel =
              typeof this.point.y === `string`
                ? this.point.y
                : formatNumber(this.point.y, lineSeriesDecimalPoints);
            return `${lineSeriesPrefix}${pointLabel}${lineSeriesSuffix}`;
          }
        };
      }

      return serie;
    });
  }

  if (options.type === `pie`) {
    // Different label formatting for pie charts
    config.plotOptions.pie.dataLabels.format = `<b>{point.name}</b>: {point.percentage:.1f} %`;
    config.tooltip.pointFormat = `{series.name}: <b>{point.percentage:.1f}%</b> / <b>{point.labelText}</b>`;
    config.series = [config.series[0]];
    config.series.colorByPoint = true;
  }

  if (options.type === `map`) {
    config.yAxis.visible = false;
    config.xAxis.visible = false;
    // Map always uses Europe map for now
    config.chart.map = `custom/europe`;

    // Set chart width to 500 and zoom by 0.9x
    // Setting to null on narrow screens to fill width of container
    config.chart.width = window.innerWidth < 500 ? null : 500;
    config.chart.height = window.innerWidth < 500 ? null : 500;
    config.chart.events = {
      load: function(e) {
        if (window.innerWidth >= 500) {
          this.mapZoom(0.88);
        }
      }
    };

    config.tooltip.formatter = function() {
      return `<b>${this.point.name}</b>:
        ${prefix}${this.point.labelText}${suffix}`;
    };

    if (!options.disableDataLabels) {
      // Show two later code
      config.plotOptions.series.dataLabels.format = null;
      config.plotOptions.map.dataLabels.formatter = function() {
        if (
          this.point.properties &&
          this.point.properties.labelrank.toString() < 5
        ) {
          return this.point[`iso-a2`];
        }
      };
    }

    let dataClasses;
    if (options.colorAxisRange && options.colorAxisRange.length > 0) {
      dataClasses = formatDataClasseSteps(
        options.colorAxisRange,
        decimalPoints
      );
    } else {
      dataClasses = getDataClasses(config.series[0].data, decimalPoints);
    }

    config.colorAxis = {
      dataClasses: dataClasses,
      dataClassColor: `category`
    };
    config.legendItems = dataClasses;
  }

  if (options.type === `scatter` || options.type === `bubble`) {
    // xAxis
    config.xAxis = {
      softMin: 0,
      title: {
        enabled: true,
        text: xAxisLabel
      },
      startOnTick: false,
      endOnTick: true,
      showLastLabel: true,
      labels: {
        formatter: function() {
          return formatNumber(this.value, decimalPoints);
        }
      }
    };

    config.plotOptions.series.dataLabels.formatter = function() {
      return `${this.point.labelText}`;
    };

    if (showRegression) {
      // Map data into pairs of numbers
      let data = config.series[0][`data`].map(item => {
        return [item.x, item.y];
      });
      // Remove any non numbers from data
      data = data.filter(item => {
        if (typeof item[0] === `number` && typeof item[1] === `number`) {
          return true;
        } else {
          return false;
        }
      });
      const slope = regression.linear(data);
      const maxPoint = maxBy(slope.points, 0);
      const minPoint = minBy(slope.points, 0);

      config.series.push({
        type: `line`,
        name: `Regression Line`,
        data: [minPoint, maxPoint],
        marker: {
          enabled: false
        },
        states: {
          hover: {
            lineWidth: 0
          }
        },
        enableMouseTracking: false,
        dataLabels: {
          enabled: false
        }
      });
    }

    config.tooltip.formatter = function() {
      const xVal = formatNumber(this.x, decimalPoints);
      const yVal = formatNumber(this.y, decimalPoints);
      let html = `<b>${this.point.labelText}
        <b><br>${xAxisLabel}: <b>${prefix}${xVal}${suffix}
        </b><br>${yAxisLabel}: <b>${prefix}${yVal}${suffix}</b>`;

      if (options.type === `bubble`) {
        const zVal = formatNumber(this.point.z, decimalPoints);
        html =
          html + `<br>${options.yAxisLabel2}: <b>${prefix}${zVal}${suffix}</b>`;
      }

      return html;
    };
  }

  if (options.groupSeries && options.stacking !== `none`) {
    config.yAxis.stackLabels = {
      enabled: true,
      verticalAlign: `bottom`,
      crop: false,
      overflow: `none`,
      y: 20,
      formatter: function() {
        return this.stack;
      }
    };
    config.xAxis.labels.y = 40;

    // Assuming groups of 2 series in consecutive order.
    // Colour consecutive series with same colour.
    // Example series:
    // - Executives (Europe) -> 1
    // - Executives (US) -> 1
    // - Staff + Other (Europe) -> 2
    // - Staff + Other (US) -> 2
    const groupCount = Math.floor(config.series.length / 2);
    for (let i = 0; i < groupCount; i++) {
      const index = i * 2;
      config.series[index].colorIndex = i;
      config.series[index + 1].colorIndex = i;
    }
  }

  if (options.type === `heatmap`) {
    config.series[0][`dataLabels`] = {
      enabled: true,
      formatter: function() {
        return `${prefix}${this.point.value}${suffix}`;
      }
    };
    config.yAxis[`title`] = null;
    config.yAxis[`labels`][`formatter`] = null;
    config.yAxis[`labels`][`enabled`] = true;
    config.yAxis[`labels`][`padding`] = 0;
    config.yAxis[`lineWidth`] = 0;
    config.yAxis[`categories`] = config.series[0].yAxisLabels;
    config.yAxis[`overflow`] = `allow`;
    config.yAxis[`staggerLines`] = 2;
    config.yAxis[`labels`][`step`] = 1;

    let dataClasses;
    if (options.colorAxisRange && options.colorAxisRange.length > 0) {
      dataClasses = formatDataClasseSteps(
        options.colorAxisRange,
        decimalPoints
      );
    } else {
      dataClasses = getDataClasses(config.series[0].data, decimalPoints);
    }
    config.colorAxis = {
      dataClasses: dataClasses,
      dataClassColor: `category`
    };
    config.legendItems = dataClasses;

    if (!options.disableDataLabels) {
      // Show two later code
      config.plotOptions.series.dataLabels.formatter = function() {
        return formatNumber(this.point.value, decimalPoints);
      };
      config.plotOptions.series.dataLabels.allowOverlap = true;
    } else {
      config.plotOptions.series.dataLabels.enabled = false;
    }

    config.tooltip.formatter = function() {
      return `<b>${this.point.labelText}</b>:
        ${prefix}${this.point.value}${suffix}`;
    };
  }

  if (options.type === `treemap`) {
    config.yAxis.visible = false;
    config.xAxis.visible = false;

    // Highcharts won't seem to work out colorAxis automatically with treemap
    // so we'll have to do it ourselves.
    let dataClasses;
    if (options.colorAxisRange && options.colorAxisRange.length > 0) {
      dataClasses = formatDataClasseSteps(
        options.colorAxisRange,
        decimalPoints
      );
    } else {
      dataClasses = getDataClasses(config.series[0].data, decimalPoints);
    }
    config.series[0].data = config.series[0].data.map(point => {
      const foundIndex = findIndex(dataClasses, item => {
        return item.to < point.value;
      });
      point.colorIndex = foundIndex > 0 ? foundIndex - 1 : 0;
      return point;
    });
    config.legendItems = dataClasses;

    if (!options.disableDataLabels) {
      // Need to remove serires dataLabel for treemap one to work
      config.plotOptions.series.dataLabels.formatter = null;
    }

    config.tooltip.formatter = function() {
      return `<b>${this.point.name}</b>:
        ${prefix}${this.point.value}${suffix}`;
    };
  }

  // Modify series data for all chart types
  config.series = config.series.map(serie => {
    // Protect against null values (they'll crash Highcharts)
    if (serie.type === null) delete serie.type;
    if (serie.yAxis === null) delete serie.yAxis;
    if (serie.zIndex === null) delete serie.zIndex;

    // apply the disableDataLabels option
    if (options.disableDataLabels) {
      serie.dataLabels = {
        enabled: false
      };
    }

    // Allow string data labels, set value to 0 if so.
    serie.data = serie.data.map(point => {
      if (typeof point !== `object`) {
        return {
          y: typeof point === `string` ? 0 : point,
          labelText:
            typeof point === `string`
              ? point
              : formatNumber(point, decimalPoints)
        };
      } else if (options.type === `heatmap`) {
        let valueDecimals = point.value;
        if (typeof vaue !== `string`) {
          valueDecimals = formatNumber(point.value, decimalPoints);
        }
        return {
          x: point.x,
          y: point.y,
          labelText: point.labelText,
          valueDecimals: valueDecimals,
          value: point.value
        };
      } else if (options.type === `map`) {
        let labelText = point.value;
        if (typeof vaue !== `string`) {
          labelText = formatNumber(point.value, decimalPoints);
        }
        return {
          code: point.code,
          value: point.value,
          labelText: labelText,
          className:
            typeof point.value === `string`
              ? `highcharts-point-string-value`
              : ``
        };
      } else {
        return point;
      }
    });

    return serie;
  });
  return config;
};
