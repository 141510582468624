import React from "react";
import { InstantSearch } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch";

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_ONLY_API_KEY,
  {
    _useRequestCache: true
  }
);

const searchClient = {
  search(requests) {
    const shouldSearch = requests.some(({ params }) => params.query !== ``);
    if (shouldSearch) return algoliaClient.search(requests);

    // override the default 'empty' search with 0 results
    return Promise.resolve({
      results: [{ hits: [] }]
    });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues
};

const SearchProvider = ({ children }) => (
  <InstantSearch
    // appId={process.env.GATSBY_ALGOLIA_APP_ID}
    // apiKey={process.env.GATSBY_ALGOLIA_SEARCH_ONLY_API_KEY}
    indexName={process.env.GATSBY_ALGOLIA_ALL_DATA_CHART_INDEX}
    searchClient={searchClient}
  >
    {children}
  </InstantSearch>
);

export default SearchProvider;
