import React from "react";
import PropTypes from "prop-types";
import { Link, StaticQuery, graphql } from "gatsby";
// import get from "lodash/get";

import { BrowserView } from "react-device-detect";

import Svg from "../Svg";
import styles from "./index.module.css";
import logoAtomicoSmall from "./logoAtomicoSmall.svg";
import logoAtomicoLarge from "./logoAtomicoLarge.svg";
import logoSlush from "./logoSlush.svg";
import logoOrrick from "./logoOrrick.svg";
import downArrow from "./downArrow.svg";
import pointer from "./pointer.svg";

import SearchInput from "../../components/SearchInput";
import SearchPanel from "../../components/SearchInput/SearchPanel";

const isActive = ({ location }) => {
  return location.pathname.startsWith(`/chapter`)
    ? { className: styles.navLinkActive }
    : null;
};

export class TopBarWithoutStaticQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMore: false, scrolling: false };

    this.toggleTopBarMore = this.toggleTopBarMore.bind(this);
    this.closeTopBarMore = this.closeTopBarMore.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll);
  }

  handleScroll(event) {
    const topRoom = 160;
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (
      window.scrollY !== 0 &&
      window.pageYOffset >= topRoom &&
      this.state.scrolling !== true
    ) {
      this.setState({ scrolling: true });
    }
  }

  toggleTopBarMore() {
    this.setState(prevState => ({ showMore: !prevState.showMore }));
  }

  closeTopBarMore() {
    this.setState({ showMore: false });
  }

  render() {
    const { showMore } = this.state;
    const { reportPath } = this.props;
    const isHome = this.props.location.pathname == `/`;
    const navItemMoreClass = showMore
      ? styles.navItemMoreExpanded
      : styles.navItemMore;

    const arrowClass = showMore ? styles.downArrowExpanded : styles.downArrow;
    const scrollingClass = isHome ? styles.appeared : styles.appearedNotHome;
    const scrollingClassHidden = isHome ? styles.hidden : styles.hiddenNotHome;

    return (
      <div className={styles.headingWrapper}>
        <div className={styles.root}>
          <div
            className={
              styles.titleWrapper +
              (this.state.scrolling
                ? ` ` + scrollingClass
                : ` ` + scrollingClassHidden)
            }
          >
            <h1 className={styles.title}>
              <Link className={styles.titleLink} to="/">
                <Svg
                  className={styles.titleLogoSmall}
                  svg={logoAtomicoSmall}
                  alt="Atomico"
                />
                <Svg
                  className={styles.titleLogoLarge}
                  svg={logoAtomicoLarge}
                  alt="Atomico"
                />
              </Link>
            </h1>
            <div className={styles.partnership}>
              <span className={styles.partnershipText}>In Partnership with</span>
              <div className={styles.slushLogo}>
                <a
                  href="http://www.slush.org"
                  className={styles.logoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Svg
                    className={styles.slushLogoImage}
                    svg={logoSlush}
                    alt="Slush logo"
                  />
                </a>
              </div>
              <span className={styles.partnershipText}>&amp;</span>
              <div className={styles.orrickLogo}>
                <a
                  href="https://www.orrick.com"
                  className={styles.logoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Svg
                    className={styles.orrickLogoImage}
                    svg={logoOrrick}
                    alt="Orrick logo"
                  />
                </a>
              </div>
            </div>
          </div>
          <ul className={styles.nav}>
            <li className={styles.navItem}>
              <Link
                getProps={isActive}
                className={styles.navLink}
                to={reportPath}
              >
                The Report
              </Link>
            </li>
            <li className={styles.navItemCollapse}>
              <Link className={styles.navLink} to="/chapter/about/">
                About
              </Link>
            </li>
            <li className={navItemMoreClass} onClick={this.toggleTopBarMore}>
              More
              <Svg className={arrowClass} svg={downArrow} />
              <ul className={styles.moreNav} onClick={this.closeTopBarMore}>
                <Svg className={styles.pointerImage} svg={pointer} />
                <li className={styles.moreNavItem}>
                  <a
                    className={styles.navLink}
                    href="https://2017.stateofeuropeantech.com"
                  >
                    2017 Report
                  </a>
                </li>
                <li className={styles.moreNavItem}>
                  <a
                    className={styles.navLink}
                    href="https://2018.stateofeuropeantech.com"
                  >
                    2018 Report
                  </a>
                </li>
                <li className={styles.moreNavItem}>
                  <a
                    className={styles.navLink}
                    href="#"
                    onClick={this.props.onDownloadClick}
                  >
                    Download
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <div
            className={
              styles.searchInputRoot +
              (this.state.scrolling
                ? ` ` + scrollingClass
                : ` ` + scrollingClassHidden)
            }
          >
            <BrowserView>
              <SearchInput reset={null} />
              <SearchPanel />
            </BrowserView>
          </div>
        </div>
        <div className={styles.linkBanner}>
          <span className={styles.blurb}>
            The State of European Tech 2020 is now live
          </span>
          <a
            href="https://2020.stateofeuropeantech.com/"
            className={styles.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            READ IT HERE
          </a>
        </div>
      </div>
    );
  }
}

TopBarWithoutStaticQuery.propTypes = {
  reportPath: PropTypes.string,
  toggleOnboarding: PropTypes.func,
  onDownloadClick: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        soetChapter {
          path
        }
      }
    `}
    render={data => (
      <TopBarWithoutStaticQuery reportPath={data.soetChapter.path} {...props} />
    )}
  />
);
