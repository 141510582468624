import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { graphql, StaticQuery } from "gatsby";
import { ShareLinkedIn, ShareTwitter } from "../Share";
import Svg from "../Svg";
import ActionButton from "../ActionButton";
import PartnerLogo from "../PartnerLogo";

import styles from "./index.module.css";
import watermark from "./watermark.svg";
import copylink from "./copylink.svg";

class FigureFooterWithoutStaticQuery extends React.Component {
  /* ReportNav lives higher up the component tree, here we just have styles
     on .contentWrapper that leaves space for it
  */
  constructor(props) {
    super(props);
    this.state = {
      value: ``,
      copied: false
    };
  }

  render() {
    const {
      onDownloadClick,
      showWatermark,
      sources,
      title,
      chartId,
      groupId,
      siteUrl
    } = this.props;

    const linkedinText = title;
    const twitterText = title;
    const shareUrl = `${siteUrl}/chart/${chartId}-${groupId}/`;
    return (
      <div className={styles.root}>
        {sources && sources.length > 0 && (
          <dl className={styles.sources}>
            <dt className={styles.term}>Source:</dt>
            {sources.map(source => (
              <dd key={source} className={styles.definition}>
                <PartnerLogo partner={source} />
              </dd>
            ))}
          </dl>
        )}
        {onDownloadClick && (
          <div className={styles.section}>
            <div className={styles.button}>
              <ActionButton onClick={onDownloadClick} />
            </div>
            <div className={styles.socialAction}>
              <CopyToClipboard
                text={shareUrl}
                onCopy={() => this.setState({ copied: true })}
              >
                <a className={styles.rootCopy}>
                  <Svg className={styles.icon} svg={copylink} />
                </a>
              </CopyToClipboard>
            </div>
            <div className={styles.social}>
              <div className={styles.socialAction}>
                <ShareLinkedIn
                  url={`${siteUrl}`}
                  sourceUrl={shareUrl}
                  title={linkedinText}
                />
              </div>
              <div className={styles.socialAction}>
                <ShareTwitter text={twitterText} url={shareUrl} />
              </div>
            </div>
          </div>
        )}
        {showWatermark && (
          <div className={styles.watermark}>
            <Svg svg={watermark} />
          </div>
        )}
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl: url
          }
        }
      }
    `}
    render={data => (
      <FigureFooterWithoutStaticQuery
        siteUrl={data.site.siteMetadata.siteUrl}
        {...props}
      />
    )}
  />
);

FigureFooterWithoutStaticQuery.propTypes = {
  insight: PropTypes.string,
  onDownloadClick: PropTypes.func,
  showWatermark: PropTypes.bool,
  sources: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  chartId: PropTypes.number,
  groupId: PropTypes.number,
  siteUrl: PropTypes.string
};
