import React from "react";
import PropTypes from "prop-types";

import { Link, StaticQuery, graphql } from "gatsby";
import Svg from "../Svg";
import logoAtomicoLarge from "../TopBar/logoAtomicoLarge.svg";
import logoSlush from "../TopBar/logoSlush.svg";
import logoOrrick from "../TopBar/logoOrrick.svg";

// import PrimaryButton from "../PrimaryButton";
import styles from "./index.module.css";

const isActive = ({ location }) => {
  return location.pathname.startsWith(`/chapter`)
    ? { className: styles.navLinkActive }
    : null;
};

class Footer extends React.Component {
  render() {
    const { reportPath, toggleOnboarding } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <ul className={styles.nav}>
            <li className={styles.navItem}>
              <Link
                getProps={isActive}
                className={styles.navLink}
                to={reportPath}
              >
                2019 Report
              </Link>
            </li>
            <li className={styles.navItemCollapse}>
              <a
                className={styles.navLink}
                href="https://2018.stateofeuropeantech.com"
              >
                2018 Report
              </a>
            </li>
            <li className={styles.navItemCollapse}>
              <a
                className={styles.navLink}
                href="https://2017.stateofeuropeantech.com"
              >
                2017 Report
              </a>
            </li>
            <li className={styles.navItemCollapse}>
              <Link className={styles.navLink} to="/chapter/about/">
                About the report
              </Link>
            </li>
            <li className={styles.navItemCollapse}>
              <a
                href="#"
                className={styles.navLink}
                onClick={e => {
                  e.preventDefault();
                  toggleOnboarding();
                }}
              >
                How to use the site
              </a>
            </li>
          </ul>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>
              <Link className={styles.titleLink} to="/">
                <Svg
                  className={styles.titleLogoLarge}
                  svg={logoAtomicoLarge}
                  alt="Atomico"
                />
              </Link>
            </h1>
            <div className={styles.partnership}>
              <span className={styles.partnershipText}>
                In Partnership with
              </span>
              <div className={styles.slushLogo}>
                <a
                  href="http://www.slush.org"
                  className={styles.logoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Svg
                    className={styles.slushLogoImage}
                    svg={logoSlush}
                    alt="Slush logo"
                  />
                </a>
              </div>
              <span className={styles.partnershipText}>&amp;</span>
              <div className={styles.orrickLogo}>
                <a
                  href="https://www.orrick.com"
                  className={styles.logoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Svg
                    className={styles.orrickLogoImage}
                    svg={logoOrrick}
                    alt="Orrick logo"
                  />
                </a>
              </div>
            </div>
          </div>
          <ul className={styles.navFooter}>
            <li className={styles.navItem}>
              <a className={styles.navFooterLink} href="/cookies">
                Cookies Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  reportPath: PropTypes.string,
  toggleOnboarding: PropTypes.func
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        soetChapter {
          path
        }
      }
    `}
    render={data => <Footer reportPath={data.soetChapter.path} {...props} />}
  />
);
