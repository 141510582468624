import React from "react";
import "./index.scss";
import { connectRefinementList } from "react-instantsearch-dom";

class RefinementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: {},
      clickedId: {}
    };
    this.determineItemStyle = this.determineItemStyle.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  determineItemStyle(i) {
    const isItemSelected = this.state.clickedId === i;
    return isItemSelected ? i + `active` : ``;
  }

  handleClick(event) {
    const id = event.target.id;
    this.setState({
      clickedId: id
    });
  }

  render() {
    const { items, refine } = this.props;

    return (
      <div className={`aisRefinementList`}>
        {items.map((item, i) => {
          return (
            <div className={item.label} key={item.label}>
              <div
                className={
                  `searchFacet` + ` ` + this.determineItemStyle(item.label)
                }
                id={item.label}
                key={i}
                onClick={event => {
                  event.preventDefault;
                  refine(item.label);
                  this.setState(prevState => ({
                    clickedId: item.label
                  }));
                  // this.setState({ clicked: item.label });
                  // this.handleClick(event);
                }}
              >
                {item.label}
              </div>
            </div>
          );
        })}
        <div
          onClick={event => {
            event.preventDefault;
            refine(``);
            this.setState(prevState => ({
              clickedId: `clear`
            }));
          }}
          className={`clearAll`}
        >
          Reset
        </div>
      </div>
    );
  }
}

const CustomRefinementList = connectRefinementList(RefinementList);

export default CustomRefinementList;
