import React, { Component } from "react";
import PropTypes from "prop-types";
import Observer from "@researchgate/react-intersection-observer";

/** Mark an element once it approaches the edge of the viewport */
/** Fires once and then stops (does not mark as non-visible if element later scrolls out of view */
export default class ViewableMonitor extends Component {
  static propTypes = {
    tag: PropTypes.node,
    children: PropTypes.func.isRequired
  };

  static defaultProps = {
    tag: `div`
  };

  state = {
    isIntersecting: false
  };

  handleChange = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) unobserve();
    this.setState({ isIntersecting }); // eslint-disable-line
  };

  render() {
    const { tag: Tag, children, ...rest } = this.props;

    return (
      <Observer {...rest} onChange={this.handleChange} rootMargin="200px">
        <Tag style={{ width: `100%` }}>
          {children(this.state.isIntersecting)}
        </Tag>
      </Observer>
    );
  }
}
