import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import NavArticleList from "../NavArticleList";
import Svg from "../../Svg";
import Highlight from "../../Highlight";
import chapterArrowIcon from "./chapterArrowIcon.svg";
import styles from "./index.module.css";
import AnimateHeight from "react-animate-height";
const shouldShowArticles = (isActiveChapter, forceShowArticles, collapse) => {
  // most chapters, most of the time
  let showArticles = false;

  // user preference (if set) can optionally toggle visible articles
  if (collapse !== null) showArticles = collapse;

  // show articles for the 'active' chapter are always visible
  if (isActiveChapter === true) showArticles = true;

  // sometimes override regardless of other settings
  if (forceShowArticles === true) showArticles = true;

  return showArticles;
};
class NavChapter extends React.Component {
  constructor(props) {
    super(props);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.navigateToChapter = this.navigateToChapter.bind(this);
    this.chapterRef = React.createRef();
  }

  state = {
    collapse: null // null, true or false
  };

  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.activeId !== this.props.activeId &&
  //     this.props.activeId === this.props.chapter.entityId
  //   ) {
  //     this.props.scrollToActiveChapter(this.props.activeId);
  //   }
  // }

  componentDidMount() {
    this.props.updateRefMap(this.props.chapter.entityId, this.chapterRef);
  }

  toggleCollapse(event) {
    event.preventDefault();
    // toggle has an initial state of 'null' to indicate no preference
    this.setState({ collapse: !this.state.collapse }); // !null is true
  }

  navigateToChapter(event) {
    event.preventDefault();
    this.props.closeReportNav();
    navigate(this.props.chapter.path);
  }

  render() {
    const {
      chapter,
      showIcon,
      activePathname,
      isActiveChapter,
      forceShowArticles,
      closeReportNav
    } = this.props;
    const { collapse } = this.state;
    const showArticles = shouldShowArticles(
      isActiveChapter,
      forceShowArticles,
      collapse
    );

    return (
      <div className={styles.root} key={chapter.entityId} ref={this.chapterRef}>
        <div className={styles.chapter}>
          <a
            href={chapter.path}
            onClick={this.navigateToChapter}
            className={
              activePathname === chapter.path
                ? styles.chapterLinkActive
                : styles.chapterLink
            }
          >
            <div className={styles.chapterLinkInner}>
              <span className={styles.chapterTitleNumber}>
                {chapter.titleNumber}
              </span>
              <div className={styles.chapterInfo}>
                <h2 className={styles.chapterTitle}>{chapter.title}</h2>
              </div>
            </div>
          </a>
          {showIcon && (
            <div
              className={
                isActiveChapter ? styles.toggleDisabled : styles.toggle
              }
              onClick={this.toggleCollapse}
            >
              <Svg
                svg={chapterArrowIcon}
                alt="Toggle chapter"
                className={styles.toggleIcon}
                style={showArticles ? { transform: `rotate(180deg)` } : {}}
              />
            </div>
          )}
          <Highlight
            isActive={activePathname === chapter.path}
            progress={100}
          />
        </div>
        <AnimateHeight
          duration={500}
          easing={`ease-in-out`}
          height={showArticles ? `auto` : 0}
          delay={showArticles && !this.state.collapse ? 500 : 0}
          className={styles.previews}
        >
          {/* {showArticles && ( */}
          <NavArticleList
            previews={chapter.soetArticle}
            activePathname={activePathname}
            closeReportNav={closeReportNav}
          />
          {/* )} */}
        </AnimateHeight>
      </div>
    );
  }
}

NavChapter.defaultProps = {
  updateRefMap: () => {},
  scrollToActiveChapter: () => {},
  forceShowArticles: false,
  showIcon: true,
  closeReportNav: () => {}
};

NavChapter.propTypes = {
  chapter: PropTypes.shape({
    entityId: PropTypes.number,
    path: PropTypes.string
  }),
  to: PropTypes.string,
  showIcon: PropTypes.bool,
  isActiveChapter: PropTypes.bool,
  forceShowArticles: PropTypes.bool,
  activePathname: PropTypes.string,
  updateRefMap: PropTypes.func,
  scrollToActiveChapter: PropTypes.func,
  closeReportNav: PropTypes.func
};

export default NavChapter;
