import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import styles from "./index.module.css";

import PrimaryButton from "../../PrimaryButton";
import { ShareLinkedIn, ShareTwitter } from "../../Share";

class NavActionsWithoutStaticQuery extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onDownloadClick, siteUrl } = this.props;
    return (
      <div className={styles.root}>
        <PrimaryButton onClick={onDownloadClick} downloadThin dark={true}>
          Download Report
        </PrimaryButton>
        <div className={styles.shareIcon}>
          <ShareLinkedIn
            className={styles.shareIcon}
            url={`${siteUrl}`}
            sourceUrl={siteUrl}
            title={`The State of European Tech`}
            large={true}
          />
        </div>
        <div className={styles.shareIcon}>
          <ShareTwitter
            text={`The State of European Tech`}
            url={siteUrl}
            large={true}
          />
        </div>
      </div>
    );
  }
}

// TODO: run this query directly from the Share component instead of here?
export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl: url
          }
        }
      }
    `}
    render={data => (
      <NavActionsWithoutStaticQuery
        siteUrl={data.site.siteMetadata.siteUrl}
        {...props}
      />
    )}
  />
);

NavActionsWithoutStaticQuery.propTypes = {
  onDownloadClick: PropTypes.func,
  siteUrl: PropTypes.string
};
