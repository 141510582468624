import { connect } from "react-redux";
import Splash from "../Splash";

import { requestReportDownload } from "../../state/download/report";

const withRedux = connect(
  (state, props) => ({
    reportPath: props.reportPath
  }),
  dispatch => ({
    onDownloadClick() {
      dispatch(requestReportDownload());
    }
  })
);

export default withRedux(Splash);
