import React from "react";
import { Link } from "gatsby";

import styles from "./index.module.css";

import Svg from "../Svg";
import logoAtomicoSmall from "./logoAtomicoSmall.svg";
import logoAtomicoLarge from "./logoAtomicoLarge.svg";
import logoSlush from "./logoSlush.svg";
import logoOrrick from "./logoOrrick.svg";

const LogoBlock = () => (
  <div className={styles.root}>
    <div className={styles.titleWrapper}>
      <div className={styles.title}>
        <Link className={styles.titleLink} to="/">
          <Svg
            className={styles.titleLogoSmall}
            svg={logoAtomicoSmall}
            alt="Atomico"
          />
          <Svg
            className={styles.titleLogoLarge}
            svg={logoAtomicoLarge}
            alt="Atomico"
          />
        </Link>
      </div>
      <div className={styles.partnership}>
        <p className={styles.partnershipText}>In Partnership with</p>
        <div className={styles.slushLogo}>
          <a
            href="http://www.slush.org"
            className={styles.logoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Svg
              className={styles.slushLogoImage}
              svg={logoSlush}
              alt="Slush logo"
            />
          </a>
        </div>
        <p className={styles.partnershipText}>&amp;</p>
        <div className={styles.orrickLogo}>
          <a
            href="https://www.orrick.com"
            className={styles.logoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Svg
              className={styles.orrickLogoImage}
              svg={logoOrrick}
              alt="Orrick logo"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default LogoBlock;
