import React, { useEffect, useState } from "react";
import { PageRenderer } from "gatsby";

/*
A wrapper around Gatsby's PageRenderer component.

Gatsby's PageRenderer requires a pathname and a resources object. This
component looks up the resources for a given pathname, then renders the
component.

*/

export default ({ location, pageResources }) => {
  const [resources, setResources] = useState(pageResources);

  useEffect(() => {
    async function loadRes() {
      if (!resources) {
        setResources(await window.___loader.loadPage(location.pathname));
      }
    }
    loadRes();
  });

  if (!resources) return null;

  return (
    <PageRenderer
      location={{ pathname: location.pathname }}
      pageResources={resources}
    />
  );
};
