import React from "react";
import { Index } from "react-instantsearch-dom";
// import get from "lodash/get";
import ResultsWrapper from "../ResultsWrapper";
// import ArticleHit from "../ArticleHit";
// import DataHit from "../DataHit";
import AllArticleHits from "../AllArticleHits";

// const ResultCount = connectStateResults(({ searchState, searchResults }) => {
//   const hasQuery = !!searchState && !!searchState.query;
//   if (!hasQuery) return `-`;
//   return get(searchResults, `nbHits`, 0);
// });

// const ArticleResultsTab = () => (
//   <React.Fragment>
//     Articles (<ResultCount />)
//   </React.Fragment>
// );

// const DataResultsTab = () => (
//   <React.Fragment>
//     Data (
//     <Index indexName={process.env.GATSBY_ALGOLIA_CHART_INDEX}>
//       <ResultCount />
//     </Index>
//     )
//   </React.Fragment>
// );

// const DataResultsPanel = () => {
//   return (
//     <Index indexName={process.env.GATSBY_ALGOLIA_CHART_INDEX}>
//       <ResultsWrapper hitComponent={DataHit} />
//     </Index>
//   );
// };

// const ArticleResultsPanel = () => <ResultsWrapper hitComponent={ArticleHit} />;

const AllResultsPanel = () => {
  return (
    <Index indexName={process.env.GATSBY_ALGOLIA_ALL_DATA_CHART_INDEX}>
      <ResultsWrapper hitComponent={AllArticleHits} />
    </Index>
  );
};

const SearchPanel = () => {
  return <AllResultsPanel />;
};

export default SearchPanel;
