import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Svg from "../Svg";
import titleize from "titleize";
import humanizeString from "humanize-string";

/* Import all logos - Make sure to add Colour & White to LOGOS and LOGOS_WHITE*/
import atomicoLogo from "./logos/atomico.svg";
import atomicoLogoWhite from "./logos/atomico-white.svg";
import aon from "./logos/aon.svg";
import angelList from "./logos/angellist.svg";
import angelListWhite from "./logos/angellist-white.svg";
import backstageCapital from "./logos/backstage-capital.svg";
import backstageCapitalWhite from "./logos/backstage-capital-white.svg";
import cambridgeAssociates from "./logos/cambridge-associates.svg";
import cambridgeAssociatesWhite from "./logos/cambridge-associates-white.svg";
import cbreLogo from "./logos/cbre.svg";
import cbreLogoWhite from "./logos/cbre-white.svg";
import cern from "./logos/cern.svg";
import cernWhite from "./logos/cern-white.svg";
import craft from "./logos/craft.svg";
import craftWhite from "./logos/craft-white.svg";
import dealroom from "./logos/dealroom.svg";
import dealroomWhite from "./logos/dealroom-white.svg";
import diversityVc from "./logos/diversityvc.svg";
import diversityVcWhite from "./logos/diversityvc-white.svg";
import eban from "./logos/eban.svg";
import ebanWhite from "./logos/eban-white.svg";
import ef from "./logos/ef.svg";
import efWhite from "./logos/ef-white.svg";
import eif from "./logos/eif.svg";
import esi from "./logos/esi.svg";
import eifWhite from "./logos/eif-white.svg";
import eurostat from "./logos/eurostat.svg";
import eurostatWhite from "./logos/eurostat-white.svg";
import flourish from "./logos/flourish.svg";
import flourishWhite from "./logos/flourish-white.svg";
import globalUniversityVenturing from "./logos/global-university-venturing.svg";
import globalUniversityVenturingWhite from "./logos/global-university-venturing-white.svg";
import graphext from "./logos/graphext.svg";
import graphextWhite from "./logos/graphext-white.svg";
import indeedLogo from "./logos/indeed.svg";
import indeedLogoWhite from "./logos/indeed-white.svg";
import investEuropeLogo from "./logos/invest-europe.svg";
import investEuropeLogoWhite from "./logos/invest-europe-white.svg";
import lseLogo from "./logos/lse.svg";
import lseLogoWhite from "./logos/lse-white.svg";
import meetUpLogo from "./logos/meetup.svg";
import meetUpLogoWhite from "./logos/meetup-white.svg";
import notionVentureCaptial from "./logos/notion-venture-capital.svg";
import notionVentureCaptialWhite from "./logos/notion-venture-capital-white.svg";
import patSnap from "./logos/patsnap.svg";
import patSnapWhite from "./logos/patsnap-white.svg";
import politicoLogo from "./logos/politico-pro.svg";
import politicoLogoWhite from "./logos/politico-pro-white.svg";
import preqinLogo from "./logos/preqin.svg";
import preqinLogoWhite from "./logos/preqin-white.svg";
import quidLogo from "./logos/quid.svg";
import quidLogoWhite from "./logos/quid-white.svg";
import sandpGlobalMarketIntelligence from "./logos/sandp-global-market-intelligence.svg";
import sandpGlobalMarketIntelligenceWhite from "./logos/sandp-global-market-intelligence-white.svg";
import shareworksLogo from "./logos/shareworks.svg";
import shareworksLogoWhite from "./logos/shareworks-white.svg";
import soetSurveyLogo from "./logos/the-state-of-european-tech-survey.svg";
import soetSurveyLogoWhite from "./logos/the-state-of-european-tech-survey-white.svg";
import sifted from "./logos/sifted.svg";
import siftedWhite from "./logos/sifted-white.svg";
import stackOverflowLogo from "./logos/stackoverflow.svg";
import stackOverflowLogoWhite from "./logos/stackoverflow-white.svg";
import startupHeatmapLogo from "./logos/startup-heatmap.svg";
import startupHeatmapLogoWhite from "./logos/startup-heatmap-white.svg";
import timesHigherEducationWorldUniversityRanking from "./logos/times-higher-education-world-university-ranking.svg";
import timesHigherEducationWorldUniversityRankingWhite from "./logos/times-higher-education-world-university-ranking-white.svg";
import wipo from "./logos/wipo.svg";
import wipoWhite from "./logos/wipo-white.svg";

const LOGOS = {
  "angel-list": angelList,
  "cambridge-associates": cambridgeAssociates,
  "diversity-vc": diversityVc,
  "startup-heatmap-europe": startupHeatmapLogo,
  "invest-europe": investEuropeLogo,
  "stack-overflow": stackOverflowLogo,
  "the-state-of-european-tech-survey": soetSurveyLogo,
  "politico-pro": politicoLogo,
  "notion-venture-capital": notionVentureCaptial,
  "sp-global-market-intelligence": sandpGlobalMarketIntelligence,
  "times-higher-education-world-university-ranking": timesHigherEducationWorldUniversityRanking,
  "global-university-venturing": globalUniversityVenturing,
  "backstage-capital": backstageCapital,
  atomico: atomicoLogo,
  cbre: cbreLogo,
  cern: cern,
  craft: craft,
  dealroom: dealroom,
  eif: eif,
  esi: esi,
  ef: ef,
  eban: eban,
  eurostat: eurostat,
  flourish: flourish,
  graphext: graphext,
  indeed: indeedLogo,
  lse: lseLogo,
  meetup: meetUpLogo,
  quid: quidLogo,
  preqin: preqinLogo,
  patsnap: patSnap,
  aon: aon,
  sifted: sifted,
  shareworks: shareworksLogo,
  wipo: wipo
};

const LOGOS_WHITE = {
  "angel-list": angelListWhite,
  "cambridge-associates": cambridgeAssociatesWhite,
  "diversity-vc": diversityVcWhite,
  "startup-heatmap-europe": startupHeatmapLogoWhite,
  "invest-europe": investEuropeLogoWhite,
  "stack-overflow": stackOverflowLogoWhite,
  "the-state-of-european-tech-survey-2018": soetSurveyLogoWhite,
  "notion-venture-capital": notionVentureCaptialWhite,
  "politico-pro": politicoLogoWhite,
  "sp-global-market-intelligence": sandpGlobalMarketIntelligenceWhite,
  "times-higher-education-world-university-ranking": timesHigherEducationWorldUniversityRankingWhite,
  "global-university-venturing": globalUniversityVenturingWhite,
  "backstage-capital": backstageCapitalWhite,
  atomico: atomicoLogoWhite,
  cbre: cbreLogoWhite,
  cern: cernWhite,
  craft: craftWhite,
  dealroom: dealroomWhite,
  eif: eifWhite,
  ef: efWhite,
  eban: ebanWhite,
  graphext: graphextWhite,
  eurostat: eurostatWhite,
  flourish: flourishWhite,
  indeed: indeedLogoWhite,
  lse: lseLogoWhite,
  meetup: meetUpLogoWhite,
  quid: quidLogoWhite,
  preqin: preqinLogoWhite,
  patSnap: patSnapWhite,
  shareworks: shareworksLogoWhite,
  sifted: siftedWhite,
  wipo: wipoWhite
};

const LOGOS_TEXT = {
  "sp-global-market-intelligence": `S&P Global Market Intelligence`
};

const LOGOS_LINKS = {
  "advance-hr": `https://www.advanced-hr.com/`,
  "cambridge-associates": `https://www.cambridgeassociates.com/`,
  "diversity-vc": `http://www.diversity.vc/`,
  "startup-heatmap-europe": `https://www.startupheatmap.eu/`,
  "invest-europe": `https://www.investeurope.eu/`,
  "politico-pro": `https://www.politico.eu/why-go-pro/`,
  "stack-overflow": `https://stackoverflow.com/`,
  "the-state-of-european-tech-survey-2018": `https://2018.stateofeuropeantech.com`,
  "notion-venture-capital": `https://notion.vc/`,
  "times-higher-education-world-university-ranking": `https://www.timeshighereducation.com/content/world-university-rankings`,
  "global-university-venturing": `https://globaluniversityventuring.com/`,
  atomico: `https://www.atomico.com/`,
  cbre: `https://www.cbre.co.uk/`,
  cern: `http://kt.cern/`,
  craft: `https://craft.co/`,
  dealroom: `https://dealroom.co/`,
  eban: `http://www.eban.org/`,
  eif: `http://www.eif.org/`,
  esi: `https://www.startupheatmap.eu/`,
  eurostat: `https://ec.europa.eu/eurostat`,
  flourish: `https://flourish.studio/`,
  indeed: `https://www.indeed.co.uk/`,
  linkedin: `https://www.linkedin.com/`,
  lse: `https://www.londonstockexchange.com/`,
  meetup: `https://www.meetup.com/`,
  quid: `https://quid.com/`,
  preqin: `https://www.preqin.com/`,
  aon: `https://radford.aon.com/`,
  shareworks: `https://www.shareworks.com/`,
  wipo: `https://www.wipo.int/portal/en/index.html`,
  sifted: `https://sifted.eu/`
};

const PartnerLogo = ({ partner, white }) => {
  const LOGO_SET = white ? LOGOS_WHITE : LOGOS;

  if (partner in LOGO_SET) {
    return (
      <a
        href={LOGOS_LINKS[partner]}
        target="_blank"
        rel="noopener noreferrer"
        title={partner}
      >
        <Svg className={styles.root} svg={LOGO_SET[partner]} alt={partner} />
      </a>
    );
  }

  // output the partner name as text instead of using a logo
  const partnerText = LOGOS_TEXT[partner] || titleize(humanizeString(partner));
  return <p className={styles.textLogo}>{partnerText}</p>;
};

PartnerLogo.propTypes = {
  partner: PropTypes.string,
  white: PropTypes.bool
};

export default PartnerLogo;
