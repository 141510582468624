import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.css";
import PrimaryButton from "../PrimaryButton";
import KeyFindingsStats from "../KeyFindingsStats";
import KeyFindingsList from "../KeyFindingsList";
import KeyFindingsText from "../KeyFindingsText";

import dealroomLogo from "../PartnerLogo/logos/dealroom.svg";
import investEurope from "../PartnerLogo/logos/invest-europe.svg";
import soetLogo from "../PartnerLogo/logos/the-state-of-european-tech-survey.svg";
import flourishLogo from "../PartnerLogo/logos/flourish.svg";

import SVG from "../Svg";

const KeyFindings = ({ data }) => (
  <div className={styles.root}>
    {data.map((item, index) => {
      return (
        <div id={`finding-0${index + 2}`} key={index} className={styles.block}>
          <div className={styles.blockInner}>
            <div className={styles.titleWrapper}>
              <p className={styles.number}>0{index + 2}</p>
              <div>
                <p className={styles.smallCategory}>{item.subTitle}</p>
                <h2 className={styles.title}>{item.title}</h2>
              </div>
            </div>
            <div className={styles.blockChartGrid}>
              <div className={styles.findingInfo}>
                <p>{item.subText}</p>
              </div>
              <div className={styles.flourishChart}>
                <div data-src={item.chart} className="flourish-embed"></div>
                <div className={styles.chartSources}>
                  <div className={styles.chartSource}>
                    Source:
                    {item.source === `dealroomLogo` && (
                      <SVG svg={dealroomLogo} className={styles.svgLogo} />
                    )}
                    {item.source === `investEurope` && (
                      <SVG svg={investEurope} className={styles.svgLogo} />
                    )}
                    {item.source === `soetLogo` && (
                      <SVG svg={soetLogo} className={styles.svgLogo} />
                    )}
                  </div>
                  <div className={styles.chartSource}>
                    Visualised with:
                    <SVG svg={flourishLogo} className={styles.svgLogo} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.metaRow}>
              <div className={styles.contentCol}>
                <div className={styles.contentBlock}>
                  {item.type === `text` && <KeyFindingsText data={item.data} />}
                  {item.type === `list` && <KeyFindingsList data={item.data} />}
                  {item.type === `stats` && (
                    <KeyFindingsStats data={item.data} />
                  )}
                </div>
              </div>
            </div>
            <nav className={styles.nav}>
              <div className={styles.navItem}>
                <PrimaryButton to={item.link} type="link">
                  Read more about {item.buttonText}
                </PrimaryButton>
              </div>
            </nav>
          </div>
        </div>
      );
    })}
  </div>
);

KeyFindings.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      data: PropTypes.array.isRequired // passed to sub-components
    })
  )
};

export default KeyFindings;
