/**
 * Export the ga object only if analytics is enabled for the env,
 * otherwise just export a noop mock.
 */
import isBrowserEnv from "./isBrowserEnv";
import noop from "lodash/noop";

const isDOM = isBrowserEnv();
const analytics = isDOM && window.gtag ? window.gtag : noop;

export default analytics;
