import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import styles from "./index.module.css";

const ArticleHeaderWithoutStaticQuery = ({
  title,
  titleNumber,
  entityId,
  siteUrl,
  path
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.titleBlock}>
          <h1 data-article={titleNumber} className={styles.title}>
            {title}
          </h1>
        </div>
      </div>
    </div>
  );
};

// TODO: run this query directly from the Share component instead of here?
export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl: url
          }
        }
      }
    `}
    render={data => (
      <ArticleHeaderWithoutStaticQuery
        siteUrl={data.site.siteMetadata.siteUrl}
        {...props}
      />
    )}
  />
);

ArticleHeaderWithoutStaticQuery.propTypes = {
  titleNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  siteUrl: PropTypes.string,
  path: PropTypes.string
};
