import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { formatTableValue } from "../../utils/highchartsConfigurator";

class TableExport {
  constructor(group, decimalPoints) {
    this.getCSV = function() {
      let csv = `,`;

      csv += group.categories.join(`,`);
      csv += `\n`;

      group.series.forEach(series => {
        csv += `${series.name},`;
        series.data.forEach(data => {
          csv += formatTableValue(data, decimalPoints);
          csv += `,`;
        });
        csv += `\n`;
      });
      return csv;
    };
  }
}

class Table extends Component {
  constructor(props) {
    super(props);

    this.props.onChartRef(
      new TableExport(this.props.group, this.props.decimalPoints)
    );
  }

  render() {
    const { group, children, decimalPoints, tableWrap } = this.props;
    const tableClass = tableWrap ? styles.tableWrap : styles.table;

    return (
      <div className={`${styles.root} figure-table`}>
        {/* <h1 className={styles.title}>{title}</h1> */}
        <table className={tableClass}>
          <thead className={styles.thead}>
            <tr className={styles.tr}>
              <th className={styles.th} />
              {group.categories.map(category => (
                <th key={category} className={styles.th}>
                  {category}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {group.series.map((series, seriesIndex) => (
              <tr
                key={`${group.id}${series.name}${seriesIndex}`}
                className={styles.tr}
              >
                <td className={styles.td}>{series.name}</td>
                {series.data.map((data, index) => (
                  <td key={`${group.id}${index}`} className={styles.td}>
                    {formatTableValue(data, decimalPoints)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.footer}>{children}</div>
      </div>
    );
  }
}

Table.propTypes = {
  group: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.string),
    series: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        )
      })
    )
  }),
  decimalPoints: PropTypes.number,
  onChartRef: PropTypes.func,
  tableWrap: PropTypes.bool
  // title: PropTypes.string
};

export default Table;
