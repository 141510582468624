exports.onRouteUpdate = function({ location }) {
  // Don't track while developing.
  if (
    process.env.NODE_ENV === `production` &&
    typeof window.gtag === `function`
  ) {
    window.gtag(`config`, window.gtag_tracking_id, {
      page_path: (location || {}).pathname
    });
  }
};
