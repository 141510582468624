import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
// import Seo from "../Seo";
import styles from "./index.module.css";
import SearchWord from "./SearchWord.svg";
import SearchChart from "./SearchChart.svg";
import DownloadData from "./DownloadData.svg";
import DownloadCharData from "./DownloadChartData.svg";
import KeyFindings from "../KeyFindings";
import CountryHighlights from "../CountryHighlights";
import StaticFooter from "../ArticleFooterStatic";

import keyFindingsData from "./key-findings-data.json";
import countryHighlightsData from "./country-highlights-data.json";

import Img from "gatsby-image";

import SVG from "../Svg";

// Illustration can be in svg/vector format or png/jpg/bitmap. This component will
// work out which, and render the right markup. Defaults to an empty component if can't
// detect illustration type
const Illustration = ({ illustrationImage, title }) => {
  const BITMAP = `BITMAP`;
  const VECTOR = `VECTOR`;
  let illustration = false;
  if (get(illustrationImage, `childImageSharp.fluid`)) illustration = BITMAP;
  if (get(illustrationImage, `extension`) === `svg`) illustration = VECTOR;

  return (
    <React.Fragment>
      {illustration === VECTOR && (
        <div className={styles.illustration}>
          <img src={illustrationImage.publicURL} alt={title} />
        </div>
      )}
      {illustration === BITMAP && (
        <div className={styles.illustration}>
          <Img fluid={illustrationImage.childImageSharp.fluid} alt={title} />
        </div>
      )}
    </React.Fragment>
  );
};

const Chapter = ({
  entityId,
  titleNumber,
  title,
  seoTitle,
  illustrationImage,
  subTitle,
  text,
  path
  // articles,
  // location
}) => {
  return (
    <div>
      {titleNumber === `01` && (
        <React.Fragment key={titleNumber}>
          <div className={styles.topBanner}>
            <div className={styles.innerRoot}>
              <div className={styles.topBannerInner}>
                <h1 className={styles.titleBanner}>
                  <span className={styles.topBannerTitleText}>
                    Make the most of this years report
                  </span>
                </h1>
                <div className={styles.chapterContentBannerGrid}>
                  <div className={styles.chapterContentCol4}>
                    <SVG svg={SearchWord} className={styles.scrollArrowSvg} />
                    <p className={styles.smallHeader}>Keyword search</p>
                  </div>
                  <div className={styles.chapterContentCol4}>
                    <SVG svg={SearchChart} className={styles.scrollArrowSvg} />
                    <p className={styles.smallHeader}>Search for charts</p>
                  </div>
                  <div className={styles.chapterContentCol4}>
                    <SVG svg={DownloadData} className={styles.scrollArrowSvg} />
                    <p className={styles.smallHeader}>Download csv data</p>
                  </div>
                  <div className={styles.chapterContentCol4}>
                    <SVG
                      svg={DownloadCharData}
                      className={styles.scrollArrowSvg}
                    />
                    <p className={styles.smallHeader}>Download chart images</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.rootKeyFindings}
              id={`chapter-${entityId}`}
              data-observe-position
              data-pathname={path}
            >
              <div id={`chapter-wrapper`} className={styles.flexCenterVertical}>
                <h1 data-chapter={titleNumber} className={styles.title}>
                  <span className={styles.titleText}>{title}</span>
                </h1>
                <Illustration
                  illustrationImage={illustrationImage}
                  title={title}
                />
              </div>
              <div className={styles.chapterContent}>
                <span className={styles.titleTextMobile}>{title}</span>
                <div className={styles.chapterContentColThird}></div>
                <div className={styles.chapterContentColTwoThirds}>
                  <p hidden className={styles.firstParagraph}>
                    {subTitle}
                  </p>
                </div>
              </div>
            </div>
            <CountryHighlights index={1} data={countryHighlightsData} />
            <KeyFindings data={keyFindingsData} />
            <StaticFooter />
          </div>
        </React.Fragment>
      )}
      {titleNumber !== `01` && (
        <div
          className={styles.root}
          id={`chapter-${entityId}`}
          data-observe-position
          data-pathname={path}
        >
          <div className={styles.innerRoot}>
            <div id={`chapter-wrapper`} className={styles.flexCenterVertical}>
              <h1 data-chapter={titleNumber} className={styles.title}>
                <span className={styles.titleText}>{title}</span>
              </h1>
              <Illustration
                illustrationImage={illustrationImage}
                title={title}
              />
            </div>
            <div className={styles.chapterContent}>
              <span className={styles.titleTextMobile}>{title}</span>
              <div className={styles.chapterContentColThird}></div>
              <div className={styles.chapterContentColTwoThirds}>
                <p hidden className={styles.firstParagraph}>
                  {subTitle}
                </p>
                <div
                  className={styles.firstParagraph}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Chapter.propTypes = {
  entityId: PropTypes.number,
  illustrationImage: PropTypes.shape({
    fluid: PropTypes.shape({}),
    extension: PropTypes.string,
    publicURL: PropTypes.string
  }),
  titleNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  seoTitle: PropTypes.string,
  path: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      path: PropTypes.string,
      title: PropTypes.string,
      titleNumber: PropTypes.string
    })
  )
};

export default Chapter;
